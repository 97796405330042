import React, { memo, useCallback, useEffect, useState } from 'react';
import { View, StyleSheet, TouchableOpacity, Image } from 'react-native';
import { useMutation, useQuery } from '@apollo/react-hooks';
import KeyboardArrowDownOutlinedIcon from '@material-ui/icons/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlined from '@material-ui/icons/KeyboardArrowUpOutlined';
import SubjectIcon from '@material-ui/icons/Subject';

import { Text, TextInput, Button, DatePicker, Infotip } from '../../core-ui';
import { colors, spacing } from '../../constants/theme';
import { VerificationRequests_requestVerificationAdvance_row as Data } from '../../../../generated/VerificationRequests';
import { Modal } from '../../../../components';
import { UPDATE_OWNER } from '../../graphql/mutations';
import {
  DocumentProblemType,
  Gender,
  StatusVerify,
  UnverifiedReason,
} from '../../../../generated/globalTypes';
import { Dropdown, UnverifiedCategories } from '../../components/dropdowns';
import { genderType } from '../../constants/genderType';
import { fonts } from '../../../../constants/theme';
import { doneCheck, close } from '../../../../../assets';
import { ButtonResetOTP, SegmentedControl } from '../../components';
import {
  checkPhoneNumber,
  checkPhoneNumberFormat,
  toPascalCase,
} from '../../helpers';
import {
  UpdateOwner,
  UpdateOwnerVariables,
} from '../../../../generated/UpdateOwner';
import validateEmail from '../../../../helpers/validateEmail';
import { GET_UPDATED_USER_HISTORY } from '../../graphql/queries';
import {
  GetUpdatedUserHistory,
  GetUpdatedUserHistoryVariables,
} from '../../../../generated/GetUpdatedUserHistory';
import { getFormattedSentenceDate } from '../../../../helpers/formatDate';
import { useSnackbar } from '../../context';
import { unverifiedDocumentType } from '../../constants/unverifiedDocument';

import { OwnerLogChanges } from './types';
import { Collapsible } from './Collapsible';
import CollapsedItem from './OwnerCollapsedItem';

type Props = {
  data: Data;
  viewMode?: boolean;
  refetchData: () => void;
  onClose: () => void;
};

type Changes = {
  gender: boolean;
  birthDate: boolean;
  name: boolean;
  email: boolean;
  phone: boolean;
  status: boolean;
  gform: boolean;
};

const OwnerSuperEditForm = memo((props: Props) => {
  const { data, viewMode = false, refetchData, onClose } = props;
  const { openSnackbar } = useSnackbar();

  const [selectedTab, setSelectedTab] = useState('FORM');
  const [submitError, setSubmitError] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);

  //=============================================
  // states
  const [newPhone, setNewPhone] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [isEmailError, setIsEmailError] = useState(false);
  const [isNameError, setIsNameError] = useState(false);
  const [dataFormId, setDataFormId] = useState('');
  const [status, setStatus] = useState(
    data.status === StatusVerify.VERIFIED ? 1 : 0,
  );
  const [gender, setGender] = useState<Gender | null>(null);
  const [birthDate, setBirthDate] = useState<Date | null>(null);

  let [unverifiedDocument, setUnverifiedDocument] = useState<Array<string>>([
    '',
  ]);
  let [unverifiedProblemFound, setUnverifiedProblem] = useState<Array<string>>([
    '',
  ]);
  let [nextActionDesc, setNextActionDesc] = useState<Array<string>>(['']);
  const [changes, setChanges] = useState<Changes>({
    gender: false,
    birthDate: false,
    name: false,
    email: false,
    phone: false,
    status: false,
    gform: false,
  });

  const ownerStatusOptions = [StatusVerify.UNVERIFIED, StatusVerify.VERIFIED];
  const ownerStatusOptionTexts = ownerStatusOptions.map(toPascalCase);

  const centangPernyataan = data.user?.agreementStatementRegal
    ? data.user.agreementStatementRegal.agreementStatementApprove || false
    : data.user?.profile?.agreementStatement || false;

  //=============================================
  // checks

  const openModal = useCallback(() => {
    const regex = /^[a-zA-Z\s]+$/;
    if (!!name && !regex.test(name)) {
      setIsNameError(true);
      return;
    }
    setModalOpen(true);
  }, [name]);

  const closeModal = useCallback(() => {
    setModalOpen(false);
  }, []);

  let isChanged = useCallback(() => {
    const firstStatus = data.status === StatusVerify.VERIFIED ? 1 : 0;
    if (gender != null && gender !== data.user?.profile?.gender) {
      return true;
    } else if (
      birthDate != null &&
      birthDate !== data.user?.profile?.birthDate
    ) {
      return true;
    } else if (name !== '' && name !== data.user?.profile?.name) {
      return true;
    } else if (email !== '' && email !== data.user?.profile?.email) {
      return true;
    } else if (newPhone !== '' && newPhone !== data.user?.phoneNumber) {
      return true;
    } else if (status !== firstStatus) {
      return true;
    } else if (dataFormId !== '') {
      return true;
    } else if (
      unverifiedDocument.length !== 1 ||
      unverifiedDocument[0] !== ''
    ) {
      return true;
    } else if (nextActionDesc.length !== 1 || nextActionDesc[0] !== '') {
      return true;
    } else if (
      unverifiedProblemFound.length !== 1 ||
      unverifiedProblemFound[0] !== ''
    ) {
      return true;
    }
    return false;
  }, [
    gender,
    birthDate,
    name,
    email,
    newPhone,
    data.user,
    status,
    data.status,
    unverifiedDocument,
    nextActionDesc,
    unverifiedProblemFound,
    dataFormId,
  ]);

  let isDisabled = (): boolean => {
    if (isChanged()) {
      let emailvalid = !!email ? validateEmail(email) : true;

      let phoneValid = !!newPhone
        ? !checkPhoneNumberFormat(newPhone)
        : !checkPhoneNumberFormat(data.user?.phoneNumber || '');

      if (changes.phone && dataFormId.trim() === '') {
        return true;
      }
      if (changes.gender) {
        return false;
      }
      if (changes.birthDate) {
        return false;
      }

      if (changes.gender) {
        return false;
      }
      if (changes.birthDate) {
        return false;
      }

      if (changes.status && status === 0) {
        if (
          !checkEmptyEntryInArray(unverifiedDocument) &&
          !checkEmptyEntryInArray(unverifiedProblemFound) &&
          !checkEmptyEntryInArray(nextActionDesc)
        ) {
          return false;
        } else {
          return true;
        }
      } else if (status === 0) {
        if (
          !checkEmptyEntryInArray(unverifiedDocument) &&
          !checkEmptyEntryInArray(unverifiedProblemFound) &&
          !checkEmptyEntryInArray(nextActionDesc)
        ) {
          return false;
        } else {
          return true;
        }
      }
      return !(emailvalid && phoneValid);
    }
    return true;
  };
  let useDebounceEmailFormat = (value: string) => {
    const [isValid, setIsValid] = useState<boolean>(false);
    useEffect(() => {
      let check = setTimeout(() => {
        if (value !== '') {
          if (validateEmail(value)) {
            setIsValid(true);
          } else {
            setIsValid(false);
          }
        } else {
          setIsValid(true);
        }
        return () => {
          clearTimeout(check);
        };
      }, 500);
    }, [value]);

    return isValid;
  };

  //=============================================
  // operations

  let valid = useDebounceEmailFormat(email);
  useEffect(() => {
    valid ? setIsEmailError(false) : setIsEmailError(true);
  }, [valid]);

  const resetState = () => {
    setSubmitError(false);
    setNewPhone('');
    setEmail('');
    setBirthDate(null);
    setGender(null);
    setDataFormId('');
    setChanges({
      birthDate: false,
      email: false,
      gender: false,
      name: false,
      phone: false,
      status: false,
      gform: false,
    });
  };

  const checkEmptyEntryInArray = (arr: Array<string>) => {
    let result = arr.filter((item) => item.trim() === '').length;
    return result > 0;
  };

  const [updateOwnerData, { loading }] = useMutation<
    UpdateOwner,
    UpdateOwnerVariables
  >(UPDATE_OWNER, {
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      openSnackbar({
        variant: 'success',
        message: t(['Data telah diperbarui.', 'Data has been updated.']),
      });
      resetState();
      setModalOpen(false);
      refetchData();
      onClose();
    },
    onError: () => {
      setSubmitError(true);
      setModalOpen(false);
    },
  });

  let updateData = () => {
    const convertToUnverifiedReason = (
      unverifiedDoc: Array<string>,
      unverifiedProblem: Array<string>,
      next: Array<string>,
    ) => {
      const result: Array<UnverifiedReason> = [];
      for (let index = 0; index < unverifiedDoc.length; index++) {
        let docType = DocumentProblemType.KTP;
        switch (unverifiedDoc[index]) {
          case 'KTP':
            docType = DocumentProblemType.KTP;
            break;
          case 'SELFIE':
            docType = DocumentProblemType.SELFIE;
            break;
          case 'OWNER_NAME':
            docType = DocumentProblemType.OWNER_NAME;
            break;
        }
        let item: UnverifiedReason = {
          reason: unverifiedProblem[index],
          nextAction: next[index],
          reasonType: docType,
        };
        result.push(item);
      }
      closeModal();
      return result;
    };

    const unverifiedReason: Array<UnverifiedReason> = convertToUnverifiedReason(
      unverifiedDocument,
      unverifiedProblemFound,
      nextActionDesc,
    );
    updateOwnerData({
      variables: {
        note: data.note,
        status: changes.status
          ? status === 0
            ? StatusVerify.UNVERIFIED
            : StatusVerify.VERIFIED
          : data.status,
        verificationId: data.id,
        ownerData: {
          birthDate: !!birthDate ? birthDate : data.user?.profile?.birthDate,
          gender: !!gender ? gender : data.user?.profile?.gender || Gender.MALE,
          name: !!name ? name : data.user?.profile?.name,
          email: !!email ? email : data.user?.profile?.email,
          phoneNumber: !!newPhone ? newPhone : data.user?.phoneNumber,
          googleSheetFormNumber: dataFormId,
        },
        unverifiedReason: changes.status ? unverifiedReason : null,
      },
      refetchQueries: [
        {
          query: GET_UPDATED_USER_HISTORY,
          variables: { verificationId: data.id },
        },
      ],
    });
  };

  //=============================================

  const onStatusChange = (status: number) => {
    setChanges({ ...changes, status: true });
    if (status === 1) {
      setUnverifiedDocument(['']);
      setUnverifiedProblem(['']);
      setNextActionDesc(['']);
    }
    setStatus(status);
  };

  const { data: historyData } = useQuery<
    GetUpdatedUserHistory,
    GetUpdatedUserHistoryVariables
  >(GET_UPDATED_USER_HISTORY, {
    variables: { verificationId: data.id },
  });

  const HeaderCollapsible = ({
    title,
    changes,
    collapsed,
  }: {
    title: string;
    changes: number;
    collapsed: boolean;
  }) => {
    return (
      <View style={styles.headerContainer}>
        <Text style={styles.titleText}>{title}</Text>
        <View style={styles.headerText}>
          <Text>{changes.toString()} perubahan</Text>
          {collapsed ? (
            <KeyboardArrowDownOutlinedIcon />
          ) : (
            <KeyboardArrowUpOutlined />
          )}
        </View>
      </View>
    );
  };

  const SectionContainer: React.FC = ({ children }) => {
    return <View style={styles.sectionContainer}>{children}</View>;
  };

  const renderUnverifiedCategories = () => {
    let unverifiedDropdown = unverifiedDocument.map((_, index) => {
      return (
        <UnverifiedCategories
          key={index}
          setUnverifiedDocument={setUnverifiedDocument}
          setUnverifiedProblem={setUnverifiedProblem}
          setNextActionDesc={setNextActionDesc}
          unverifiedDocument={unverifiedDocument}
          unverifiedProblem={unverifiedProblemFound}
          options={unverifiedDocumentType}
          disabledDocumentType={false}
          disabledProblem={false}
          index={index}
          nextAction={nextActionDesc}
        />
      );
    });
    return unverifiedDropdown;
  };

  return (
    <>
      <View style={styles.root}>
        <View style={styles.header}>
          <View style={styles.row}>
            <TouchableOpacity
              data-cy="header-nav-list"
              style={[
                styles.tab,
                selectedTab === 'FORM' ? styles.selectedTab : {},
              ]}
              onPress={() => setSelectedTab('FORM')}
            >
              <Text
                color={selectedTab === 'FORM' ? 'black' : 'grey'}
                bold
                size="m"
              >
                {'Form'}
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              data-cy="header-nav-wip"
              style={[
                styles.tab,
                selectedTab === 'RIWAYAT' ? styles.selectedTab : {},
              ]}
              onPress={() => setSelectedTab('RIWAYAT')}
            >
              <Text
                color={selectedTab === 'RIWAYAT' ? 'black' : 'grey'}
                bold
                size="m"
              >
                {t(['Riwayat', 'History'])}
              </Text>
            </TouchableOpacity>
          </View>
        </View>
        {selectedTab === 'FORM' ? (
          <View>
            <View
              style={
                viewMode
                  ? styles.viewMode
                  : [styles.borderTopZero, styles.border, styles.lPadding]
              }
            >
              <TextInput
                containerStyle={isNameError ? null : styles.lBottomSpacing}
                value={changes.name ? name : data.user?.profile?.name}
                onChangeText={(name) => {
                  setChanges({ ...changes, name: true });
                  setIsNameError(false);
                  setName(name);
                }}
                label={t(['Nama', 'Name'])}
                required
              />
              {isNameError && (
                <Text style={[styles.warning, styles.lBottomSpacing]}>
                  Nama tidak boleh berisi nomor atau simbol khusus
                </Text>
              )}

              <TextInput
                value={changes.phone ? newPhone : data.user?.phoneNumber}
                onChangeText={(newPhone) => {
                  setChanges({ ...changes, phone: true });
                  setNewPhone(newPhone);
                }}
                error={checkPhoneNumberFormat(newPhone)}
                label={t(['Nomor HP', 'New Phone Number'])}
                helperText={
                  newPhone !== ''
                    ? !checkPhoneNumber(newPhone)
                      ? t([
                          'No. HP yang Anda masukkan tidak sesuai format (e.g. 085156263898)',
                          'Phone Number is in the wrong format (e.g. 085156263898)',
                        ])
                      : newPhone.length < 8
                      ? t(['Minimal 8 digit angka', 'Min. 8 numbers'])
                      : newPhone.length > 14
                      ? t(['Maksimal 14 angka', 'Max. 14 numbers'])
                      : ''
                    : ''
                }
                required
              />
              <ButtonResetOTP
                containerStyle={styles.buttonResetOtp}
                userId={data.userId || ''}
              />
              <View style={styles.lBottomSpacing}>
                <TextInput
                  value={changes.email ? email : data.user?.profile?.email}
                  onChangeText={(email) => {
                    setChanges({ ...changes, email: true });
                    setEmail(email);
                  }}
                  label={t(['Email (opsional)', 'Store Code'])}
                />
                {isEmailError && (
                  <Text style={styles.warning}>
                    Tolong gunakan format email address yang benar
                  </Text>
                )}
              </View>
              <TextInput
                data-cy="owner-verification-id"
                containerStyle={styles.lBottomSpacing}
                value={data.user?.profile?.idCardType}
                onChangeText={() => {}}
                label={t(['Tipe Dokumen', 'Document Type'])}
                disabled={true}
              />
              <TextInput
                data-cy="owner-verification-id"
                containerStyle={styles.lBottomSpacing}
                value={data.user?.profile?.idCardNumber}
                onChangeText={() => {}}
                label={t([
                  'Kartu Identitas/SIM/Paspor',
                  'ID Card/SIM/Passport',
                ])}
                disabled={true}
                required
              />
              <Dropdown
                onChange={(value: Gender) => {
                  setChanges({ ...changes, gender: true });
                  setGender(value);
                }}
                label={t(['Jenis Kelamin', 'Gender'])}
                value={
                  changes.gender ? gender : data.user?.profile?.gender || null
                }
                options={genderType}
                containerStyle={styles.cardType}
                required={true}
                error={
                  gender == null ? data.user?.profile?.gender == null : false
                }
                helperText={
                  gender == null
                    ? data.user?.profile?.gender == null
                      ? t(['Kolom harus diisi', 'This field must be filled'])
                      : ''
                    : ''
                }
              />
              <DatePicker
                data-cy="owner-verification-birthdate"
                selectedDate={
                  changes.birthDate ? birthDate : data.user?.profile?.birthDate
                }
                onChange={(date) => {
                  setBirthDate(date);

                  setChanges({ ...changes, birthDate: true });
                }}
                label={t(['Tanggal Lahir', 'Birth Date'])}
                style={styles.birthDate}
                required={true}
                error={
                  birthDate == null
                    ? data.user?.profile?.birthDate == null
                    : false
                }
                helperText={
                  birthDate == null
                    ? data.user?.profile?.birthDate == null
                      ? t(['Kolom harus diisi', 'This field must be filled'])
                      : ''
                    : ''
                }
              />

              <TextInput
                data-cy="owner-verification-id"
                containerStyle={styles.lBottomSpacing}
                value={data.user?.store?.storeManagerBulk?.address}
                onChangeText={() => {}}
                label={t(['Alamat', 'Address'])}
                disabled={true}
              />
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <TextInput
                  data-cy="owner-verification-province"
                  value={data.user?.store?.storeManagerBulk?.province || ''}
                  onChangeText={() => {}}
                  label={'Provinsi'}
                  disabled={true}
                  containerStyle={styles.dropdownWidth}
                />
                <TextInput
                  data-cy="owner-verification-city"
                  value={data.user?.store?.storeManagerBulk?.city || ''}
                  onChangeText={() => {}}
                  label={'Kabupaten/Kota'}
                  disabled={true}
                  containerStyle={styles.dropdownWidth}
                />
              </View>
              {centangPernyataan ? (
                <View style={styles.attachmentRow}>
                  <View style={{ paddingBottom: 8 }}>
                    <Image source={doneCheck} style={styles.doneIcon} />
                  </View>
                  <Text bold style={styles.sudahCentangPernyataan}>
                    Sudah Centang Kalimat Pernyataan
                  </Text>
                </View>
              ) : (
                <View style={styles.attachmentRow}>
                  <View style={{ paddingBottom: 8 }}>
                    <Image source={close} style={styles.doneIcon} />
                  </View>
                  <Text bold style={styles.belumCentangPernyataan}>
                    Belum Centang Kalimat Pernyataan
                  </Text>
                </View>
              )}
              <Text bold style={styles.pernyataanHeader}>
                Kalimat Pernyataan
              </Text>
              <Text style={styles.pernyataan}>
                {centangPernyataan
                  ? data.user?.agreementStatementRegal?.agreementStatement || ''
                  : ''}
              </Text>

              <View
                style={
                  viewMode
                    ? styles.viewMode
                    : [styles.borderTopZero, styles.border, styles.lPadding]
                }
              >
                <View
                  style={[
                    styles.row,
                    styles.lBottomSpacing,
                    styles.centerAligned,
                  ]}
                >
                  <Text bold size="m" style={{ marginRight: spacing.xxs }}>
                    {t(['Status Pemilik', 'OWNER Status'])}
                  </Text>
                  <Infotip />
                </View>
                <SegmentedControl
                  data-cy="owner-verification-status"
                  containerStyle={styles.statusContainer}
                  selected={status}
                  setSelected={onStatusChange}
                  options={ownerStatusOptionTexts}
                />
                {status === 0 && (
                  <SectionContainer>
                    <Text bold size="m" style={styles.lBottomSpacing}>
                      Kategori Unverified
                      <Text bold size="s" color="red">
                        *
                      </Text>
                    </Text>
                    {renderUnverifiedCategories()}
                  </SectionContainer>
                )}
              </View>
              <TextInput
                data-cy="owner-verification-note"
                containerStyle={!viewMode && styles.lBottomSpacing}
                value={data.note}
                onChangeText={() => {}}
                leftNode={<SubjectIcon htmlColor={colors.border.primary} />}
                placeholder={t(['Catatan (Wajib)', 'Add Notes (Required)'])}
                disabled={viewMode}
                multiline
                rows={3}
                //need to use inline style otherwise it will crash (already tried)
                style={{
                  paddingBottom: 20,
                }}
              />

              <TextInput
                value={dataFormId}
                containerStyle={styles.lBottomSpacing}
                onChangeText={setDataFormId}
                label={t([
                  'Nomor Data Form di Google Sheet',
                  'Form Data Number in Google Sheet',
                ])}
              />
              {submitError && (
                <Text style={{ color: colors.text.red }}>
                  {t([
                    'Gagal mengubah data, lengkapi data" dan coba lagi',
                    'Failed to change the phone number. Please try again later.',
                  ])}
                </Text>
              )}
            </View>

            <View
              style={[
                styles.row,
                styles.lBottomSpacing,
                { justifyContent: 'flex-end' },
              ]}
            >
              <Button
                style={styles.buttonWidth}
                preset="primary"
                title={t(['Simpan', 'Save'])}
                onPress={openModal}
                disabled={isDisabled()}
              />
            </View>
          </View>
        ) : (
          <>
            {historyData?.getUpdatedUserHistory.map(
              ({ createdAt, currentData, updatedAttributes }) => {
                let returned: OwnerLogChanges = JSON.parse(
                  updatedAttributes || '',
                );
                let count = 0;
                returned.birthDate && count++;
                returned.email && count++;
                returned.gender && count++;
                returned.name && count++;
                returned.phoneNumber && count++;
                returned.googleSheetFormNumber && count++;
                returned.ownerVerified && count++;
                // console.log(returned);

                return (
                  <View style={styles.collapseContainer} key={createdAt}>
                    <Collapsible
                      onPressClosing={() => {}}
                      onPressOpening={() => {}}
                      title={
                        <HeaderCollapsible
                          changes={count}
                          collapsed={true}
                          title={getFormattedSentenceDate(new Date(createdAt))}
                        />
                      }
                      titleOpened={
                        <HeaderCollapsible
                          changes={count}
                          collapsed={false}
                          title={getFormattedSentenceDate(new Date(createdAt))}
                        />
                      }
                    >
                      <CollapsedItem
                        currentData={currentData || ''}
                        updatedData={updatedAttributes || ''}
                      />
                    </Collapsible>
                  </View>
                );
              },
            )}
          </>
        )}
      </View>
      <View>
        <Modal
          open={modalOpen}
          onClose={closeModal}
          title={t(['Simpan Perubahan', 'Save Changes'])}
          titleSize="xl"
          TextComponent={Text}
        >
          <Text style={styles.bottomContainer}>
            {t([
              'Apakah Anda sudah yakin dengan perubahan ini?',
              'Are you sure with this change?',
            ])}
          </Text>
          <View style={styles.confirmationModalButton}>
            <Button
              title={t(['Tidak', 'No'])}
              preset="secondary"
              onPress={closeModal}
            />
            <Button
              title={t(['Ya', 'Yes'])}
              onPress={updateData}
              isLoading={loading}
            />
          </View>
        </Modal>
      </View>
    </>
  );
});

const styles = StyleSheet.create({
  header: {
    backgroundColor: colors.background.white,
    flexDirection: 'row',
    height: 40,
    shadowColor: colors.background.shadow,
  },
  // headerTitle: { alignItems: 'center', flexDirection: 'row' },
  root: { width: 529, height: 1000, backgroundColor: colors.background.white },
  row: { flexDirection: 'row' },
  buttonWidth: {
    width: 150,
  },
  borderTopZero: {
    borderTopWidth: 0,
  },
  border: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: colors.button.border.primary,
  },
  confirmationModalButton: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    paddingTop: spacing.m,
    paddingRight: spacing.l,
    paddingBottom: spacing.m,
  },
  lBottomSpacing: {
    marginBottom: spacing.l,
  },
  lPadding: {
    padding: spacing.l,
  },
  tab: {
    justifyContent: 'flex-start',
    height: '100%',
    marginHorizontal: 12,
  },
  selectedTab: {
    borderBottomWidth: 2,
    borderBottomColor: colors.button.background.primary,
  },
  bottomContainer: {
    marginTop: 24,
    padding: 24,
    width: 529,
    backgroundColor: colors.background.white,
  },
  viewMode: {
    borderTopColor: colors.button.border.primary,
    borderTopWidth: 1,
    paddingTop: spacing.l,
    paddingBottom: spacing.m,
  },
  cardType: { paddingBottom: 12 },
  birthDate: {
    fontSize: fonts.sizes.small,
    paddingBottom: 24,
  },
  dropdownWidth: { width: 250 },

  sudahCentangPernyataan: {
    fontSize: 14,
    color: colors.icon.acceptGreen,
    lineHeight: 17,
    paddingBottom: 26,
  },
  attachmentRow: {
    flexDirection: 'row',
    flexWrap: 'nowrap',
    paddingTop: 17,
  },
  belumCentangPernyataan: {
    fontSize: 14,
    color: colors.icon.declineRed,
    lineHeight: 17,
    paddingBottom: 26,
  },
  doneIcon: {
    height: 20,
    width: 20,
    marginRight: 2,
  },
  pernyataanHeader: {
    textAlign: 'left',
    width: '80%',
    fontWeight: 'bold',
    fontSize: 16,
    lineHeight: 20,
    paddingBottom: 11,
  },
  pernyataan: {
    textAlign: 'left',
    fontSize: 14,
    lineHeight: 20,
  },
  centerAligned: { alignItems: 'center' },
  statusContainer: { width: '100%' },
  titleText: { fontWeight: 'bold' },
  headerContainer: {
    backgroundColor: colors.background.white,
    borderColor: colors.border.primary,
    padding: 16,
    borderWidth: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
  },
  headerText: { flexDirection: 'row', alignItems: 'center' },
  collapseContainer: { marginTop: 16 },
  warning: {
    fontSize: fonts.sizes.small,
    color: colors.text.redAlt,
  },
  sectionContainer: {
    paddingTop: spacing.l,
    marginBottom: spacing.m,
    marginHorizontal: spacing.s,
    borderTopWidth: 1,
    borderTopColor: colors.border.primary,
  },
  buttonResetOtp: {
    marginTop: 8,
  },
});

export default OwnerSuperEditForm;
