import React, { useCallback, useState } from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';
import { useMutation } from '@apollo/react-hooks';

import { Button, Text } from '../core-ui';
import { Modal } from '../../../components';
import { colors, spacing } from '../constants/theme';
import { RESET_OTP_LIMIT } from '../../../graphql/mutations/resetOtplimit';
import {
  ResetOtpLimit,
  ResetOtpLimitVariables,
} from '../../../generated/ResetOtpLimit';
import { useSnackbar } from '../context';

type Props = {
  containerStyle?: StyleProp<ViewStyle>;
  userId: string;
};

const ButtonResetOTP = ({ containerStyle, userId }: Props) => {
  const { openSnackbar } = useSnackbar();

  let [resetOtpModal, setResetOtpModal] = useState(false);

  let [resetOtpLimit, { loading }] = useMutation<
    ResetOtpLimit,
    ResetOtpLimitVariables
  >(RESET_OTP_LIMIT, {
    variables: { userId },
    onCompleted: ({ resetOtpLimit }) => {
      closeOtpModal();
      if (resetOtpLimit.success) {
        openSnackbar({
          variant: 'success',
          message: 'OTP limit has been reset',
        });
      } else {
        openSnackbar({
          variant: 'error',
          message: 'Failed to reset OTP limit',
        });
      }
    },
    onError: () => {
      openSnackbar({
        variant: 'error',
        message: 'Failed to reset OTP limit',
      });
    },
  });

  const openOtpModal = useCallback(() => {
    setResetOtpModal(true);
  }, []);
  const closeOtpModal = useCallback(() => {
    setResetOtpModal(false);
  }, []);
  const onConfirmResetOtp = useCallback(() => {
    resetOtpLimit();
  }, [resetOtpLimit]);

  return (
    <>
      <View style={[styles.container, containerStyle]}>
        <Button
          title="Reset OTP"
          style={styles.button}
          color="secondary"
          onPress={openOtpModal}
        />
      </View>
      <Modal
        open={resetOtpModal}
        onClose={closeOtpModal}
        title={t(['Simpan Perubahan', 'Save Changes'])}
        titleSize="xl"
        TextComponent={Text}
      >
        <Text style={styles.titleModal}>
          {t([
            'Apakah Anda sudah yakin dengan perubahan ini?',
            'Are you sure with this change?',
          ])}
        </Text>
        <View style={styles.confirmationModalButton}>
          <Button
            title={t(['Tidak', 'No'])}
            preset="secondary"
            onPress={closeOtpModal}
          />
          <Button
            title={t(['Ya', 'Yes'])}
            onPress={onConfirmResetOtp}
            isLoading={loading}
          />
        </View>
      </Modal>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'flex-end',
  },
  button: {
    backgroundColor: '#EB0001',
  },
  titleModal: {
    marginTop: 24,
    padding: 24,
    width: 529,
    backgroundColor: colors.background.white,
  },
  confirmationModalButton: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    paddingVertical: spacing.m,
    paddingRight: spacing.l,
  },
});

export default ButtonResetOTP;
