/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AccessScreen {
  CONDITION = "CONDITION",
  NAVIGATE = "NAVIGATE",
}

export enum AccessUser {
  ADD_STAFF = "ADD_STAFF",
  CAN_ORDER = "CAN_ORDER",
  SPEND_POINTS = "SPEND_POINTS",
}

export enum ArchiveStatus {
  ARCHIVED = "ARCHIVED",
  NORMAL = "NORMAL",
}

export enum CardSliderOrderByType {
  appUser = "appUser",
  businessUnitContactName = "businessUnitContactName",
  city = "city",
  endLiveDate = "endLiveDate",
  liveDate = "liveDate",
  name = "name",
  timeActive = "timeActive",
}

export enum CmsRequestStatus {
  APPROVED = "APPROVED",
  CANCEL = "CANCEL",
  REJECT = "REJECT",
  REVIEW = "REVIEW",
  UNMATCHED = "UNMATCHED",
  WAITING = "WAITING",
}

export enum CmsRequestStatusFilter {
  ACCEPTED = "ACCEPTED",
  CANCELED = "CANCELED",
  OVER_SLA = "OVER_SLA",
  REJECTED_BY_DISTRIBUTOR = "REJECTED_BY_DISTRIBUTOR",
  REJECTED_BY_PRINCIPAl = "REJECTED_BY_PRINCIPAl",
  REQUEST = "REQUEST",
  REVIEW = "REVIEW",
  UNMATCHED = "UNMATCHED",
}

export enum CompetitionOrderByType {
  appUser = "appUser",
  brandName = "brandName",
  buContactName = "buContactName",
  city = "city",
  closeDate = "closeDate",
  competitionStatus = "competitionStatus",
  endLiveDate = "endLiveDate",
  eventDate = "eventDate",
  liveDate = "liveDate",
  name = "name",
  submit = "submit",
  time = "time",
  winners = "winners",
}

export enum CronStatus {
  COMPLETED = "COMPLETED",
  CREATED = "CREATED",
  FAILED = "FAILED",
  ON_PROCESS = "ON_PROCESS",
}

export enum DocumentAdvanceSearchOrderByType {
  brandName = "brandName",
  documentName = "documentName",
}

export enum DocumentProblemType {
  DATA = "DATA",
  KTP = "KTP",
  OWNER_NAME = "OWNER_NAME",
  SELFIE = "SELFIE",
}

export enum EventOrderByType {
  appUser = "appUser",
  attendees = "attendees",
  brandName = "brandName",
  businessUnitContactName = "businessUnitContactName",
  city = "city",
  endLiveDate = "endLiveDate",
  eventDate = "eventDate",
  liveDate = "liveDate",
  maxParticipant = "maxParticipant",
  name = "name",
  timeEvent = "timeEvent",
  venue = "venue",
}

export enum FilterStoreStatusVerifyInput {
  REVALIDATION = "REVALIDATION",
  UNVERIFIED = "UNVERIFIED",
  UPDATED = "UPDATED",
  VERIFIED = "VERIFIED",
  VERIFIED_MERGE = "VERIFIED_MERGE",
  WAITING = "WAITING",
}

export enum FilterUserStatusVerifyInput {
  UNVERIFIED = "UNVERIFIED",
  UPDATED = "UPDATED",
  VERIFIED = "VERIFIED",
  WAITING = "WAITING",
}

export enum FilterViewDataInput {
  ALL = "ALL",
  STORE_ONLY = "STORE_ONLY",
  USER_ONLY = "USER_ONLY",
}

export enum FlagCreateUser {
  MY_TEAM = "MY_TEAM",
  REGISTER = "REGISTER",
}

export enum FolderType {
  BRAND_IMAGE = "BRAND_IMAGE",
  BRAND_PAGE = "BRAND_PAGE",
  BRAND_RANGE = "BRAND_RANGE",
  CARD_SLIDER = "CARD_SLIDER",
  COMPETITION = "COMPETITION",
  DOCUMENT = "DOCUMENT",
  EVENT = "EVENT",
  LUMPSUM = "LUMPSUM",
  NEWS = "NEWS",
  POP_UP = "POP_UP",
  PROFILE = "PROFILE",
  PROMOTION = "PROMOTION",
}

export enum FormCompetitionOrderByType {
  appUser = "appUser",
  brandName = "brandName",
  buContactName = "buContactName",
  city = "city",
  competitionStatus = "competitionStatus",
  endLiveDate = "endLiveDate",
  id = "id",
  link = "link",
  liveDate = "liveDate",
  name = "name",
  status = "status",
  time = "time",
}

export enum FormEventOrderByType {
  attendees = "attendees",
  brandName = "brandName",
  businessUnitContactName = "businessUnitContactName",
  city = "city",
  date = "date",
  eventStatus = "eventStatus",
  id = "id",
  name = "name",
  time = "time",
  venue = "venue",
}

export enum Gender {
  FEMALE = "FEMALE",
  MALE = "MALE",
}

export enum HistoryType {
  DEDUCT = "DEDUCT",
  EARN = "EARN",
  REDEEM = "REDEEM",
  REFUND = "REFUND",
}

export enum IdCardType {
  KTP = "KTP",
  PASSPORT = "PASSPORT",
  SIM = "SIM",
}

export enum LinkTypePopUp {
  EXTERNAL_LINK = "EXTERNAL_LINK",
  INTERNAL_LINK = "INTERNAL_LINK",
  WHATSAPP = "WHATSAPP",
}

export enum LoyaltySource {
  DOKU = "DOKU",
  TADA = "TADA",
}

export enum LumpSumAction {
  ADD = "ADD",
  DEDUCT = "DEDUCT",
}

export enum LumpSumType {
  COMPETITION = "COMPETITION",
  EVENT = "EVENT",
  PROMOTION = "PROMOTION",
  REWARD = "REWARD",
  TRANSACTION = "TRANSACTION",
}

export enum MediaSocial {
  FACEBOOK = "FACEBOOK",
  INSTAGRAM = "INSTAGRAM",
  LINKEDIN = "LINKEDIN",
  OTHER = "OTHER",
  SKYPE = "SKYPE",
  TWITTER = "TWITTER",
  WHATSAPP = "WHATSAPP",
}

export enum MissionCategory {
  MISI_TAMBAHAN = "MISI_TAMBAHAN",
  MISI_UTAMA = "MISI_UTAMA",
  SURVEY = "SURVEY",
}

export enum NewCmsRequestStatusFilter {
  NOT_PAST_DEADLINE = "NOT_PAST_DEADLINE",
  PAST_DEADLINE = "PAST_DEADLINE",
}

export enum NewsOrderByType {
  appUser = "appUser",
  brandName = "brandName",
  buContactName = "buContactName",
  city = "city",
  clickCount = "clickCount",
  endLiveDate = "endLiveDate",
  liveDate = "liveDate",
  status = "status",
  title = "title",
}

export enum NewsTypeLink {
  EXTERNAL_LINK = "EXTERNAL_LINK",
  INTERNAL_LINK = "INTERNAL_LINK",
  RANGE = "RANGE",
}

export enum OrderByArg {
  asc = "asc",
  desc = "desc",
}

export enum OrderStatus {
  CANCELLED = "CANCELLED",
  CLOSE = "CLOSE",
  OPEN = "OPEN",
  PARTIAL = "PARTIAL",
  PENDING = "PENDING",
  REJECTED = "REJECTED",
}

export enum OrderType {
  MASTER = "MASTER",
  TEMPORARY = "TEMPORARY",
}

export enum Orientation {
  HORIZONTAL = "HORIZONTAL",
  VERTICAL = "VERTICAL",
}

export enum PhoneCategory {
  ANSWERED = "ANSWERED",
  NOT_ANSWERED = "NOT_ANSWERED",
}

export enum PopUpColor {
  BLUE = "BLUE",
  GOLD = "GOLD",
  RED = "RED",
}

export enum PromotionOrderByType {
  appUser = "appUser",
  brandName = "brandName",
  buContactName = "buContactName",
  city = "city",
  endLiveDate = "endLiveDate",
  liveDate = "liveDate",
  name = "name",
  status = "status",
  time = "time",
}

export enum RedeemStatus {
  Failed = "Failed",
  Success = "Success",
}

export enum RejectedBy {
  DISTRIBUTOR = "DISTRIBUTOR",
  PRINCIPAL = "PRINCIPAL",
}

export enum RequestAgentDownloadStatus {
  CANCELED = "CANCELED",
  COMPLETED = "COMPLETED",
  ON_PROGRESS = "ON_PROGRESS",
  WAITING = "WAITING",
}

export enum RequestAgentFrom {
  ORDER = "ORDER",
  PROFILE = "PROFILE",
  REGISTER = "REGISTER",
  VERIF_ADMIN = "VERIF_ADMIN",
}

export enum RequestStatus {
  ACCEPTED = "ACCEPTED",
  CANCELED = "CANCELED",
  REJECTED = "REJECTED",
  REQUEST = "REQUEST",
  REVIEW = "REVIEW",
  UNMATCHED = "UNMATCHED",
}

export enum RequestVerificationOrderByType {
  confirmAt = "confirmAt",
  confirmBy = "confirmBy",
  createAt = "createAt",
  name = "name",
  region = "region",
  registerAt = "registerAt",
  status = "status",
  type = "type",
  updatedAt = "updatedAt",
}

export enum Role {
  STAFF = "STAFF",
  SUPERVISOR = "SUPERVISOR",
  TOKO_OWNER = "TOKO_OWNER",
}

export enum RoleAdmin {
  ADMIN = "ADMIN",
  MASTER_VERIFICATION_ADMIN = "MASTER_VERIFICATION_ADMIN",
  PRINCIPAL = "PRINCIPAL",
  SUPER_ADMIN = "SUPER_ADMIN",
  SUPER_VERIFICATION_ADMIN = "SUPER_VERIFICATION_ADMIN",
  VERIFICATION_ADMIN = "VERIFICATION_ADMIN",
}

export enum StatsCompetition {
  ALL_COMPETITION_WINNERS = "ALL_COMPETITION_WINNERS",
  ALL_USERS_WHO_ENTERED = "ALL_USERS_WHO_ENTERED",
}

export enum StatusActive {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export enum StatusAddEarnGivenPoint {
  ADD = "ADD",
  MINUS = "MINUS",
}

export enum StatusBulkPoint {
  CANCELLED = "CANCELLED",
  DONE = "DONE",
  FAIL = "FAIL",
  ON_COMMIT = "ON_COMMIT",
  ON_PROGRESS = "ON_PROGRESS",
  READY_TO_REVIEW = "READY_TO_REVIEW",
}

export enum StatusGivenPoint {
  INVALID = "INVALID",
  VALID = "VALID",
}

export enum StatusProgressTempGivenPoint {
  DONE = "DONE",
  READY_TO_REVIEW = "READY_TO_REVIEW",
}

export enum StatusType {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export enum StatusVerify {
  REVALIDATION = "REVALIDATION",
  UNVERIFIED = "UNVERIFIED",
  VERIFIED = "VERIFIED",
  VERIFIED_MERGE = "VERIFIED_MERGE",
  WAITING = "WAITING",
}

export enum StorePhotoCategory {
  STORE_PHOTO_FRONT = "STORE_PHOTO_FRONT",
  STORE_PHOTO_INSIDE = "STORE_PHOTO_INSIDE",
}

export enum TadaItemType {
  DIGITAL = "DIGITAL",
  PYSHICAL = "PYSHICAL",
}

export enum TermsAndCondition {
  AGREE = "AGREE",
  DISAGREE = "DISAGREE",
}

export enum Time {
  ALL_DAY = "ALL_DAY",
  DAY = "DAY",
  EVENING = "EVENING",
  MORNING = "MORNING",
}

export enum TypeRollBack {
  VERIFIED = "VERIFIED",
  VERIFIED_MERGE = "VERIFIED_MERGE",
}

export enum TypeVerify {
  TOKO = "TOKO",
  USER = "USER",
}

export enum ValidVisitation {
  GUARANTEED = "GUARANTEED",
  NOT_GUARANTEED = "NOT_GUARANTEED",
}

export enum VerificationClaimType {
  CLAIM = "CLAIM",
  UNCLAIM = "UNCLAIM",
}

export interface AdminWhereInput {
  AND?: AdminWhereInput[] | null;
  betaTester?: NullableBooleanFilter | null;
  brand?: BrandWhereInput | null;
  brandId?: NullableStringFilter | null;
  BulkAgent?: BulkAgentFilter | null;
  BulkPoint?: BulkPointFilter | null;
  createdAt?: DateTimeFilter | null;
  email?: StringFilter | null;
  id?: StringFilter | null;
  LogRollBackVerifyStore?: LogRollBackVerifyStoreFilter | null;
  MissionActivityHistory?: MissionActivityHistoryFilter | null;
  MissionTopupReward?: MissionTopupRewardFilter | null;
  name?: StringFilter | null;
  NOT?: AdminWhereInput[] | null;
  OR?: AdminWhereInput[] | null;
  phoneNumber?: StringFilter | null;
  RequestAgent?: RequestAgentFilter | null;
  requestVerification?: RequestVerificationFilter | null;
  role?: RoleAdmin | null;
  status?: StatusActive | null;
  updatedAt?: DateTimeFilter | null;
  updatedStoreHistory?: UpdatedStoreHistoryFilter | null;
  updatedUserHistory?: UpdatedUserHistoryFilter | null;
}

export interface AgreementRegalStatementWhereInput {
  agreementApproveDate?: NullableDateTimeFilter | null;
  agreementStatement?: StringFilter | null;
  agreementStatementApprove?: BooleanFilter | null;
  AND?: AgreementRegalStatementWhereInput[] | null;
  createdAt?: DateTimeFilter | null;
  id?: StringFilter | null;
  NOT?: AgreementRegalStatementWhereInput[] | null;
  OR?: AgreementRegalStatementWhereInput[] | null;
  updatedAt?: DateTimeFilter | null;
  user?: UserWhereInput | null;
  userId?: StringFilter | null;
}

export interface AutomaticReportRequestAgentEmailFilter {
  every?: AutomaticReportRequestAgentEmailWhereInput | null;
  none?: AutomaticReportRequestAgentEmailWhereInput | null;
  some?: AutomaticReportRequestAgentEmailWhereInput | null;
}

export interface AutomaticReportRequestAgentEmailWhereInput {
  activeSendEmail?: BooleanFilter | null;
  AND?: AutomaticReportRequestAgentEmailWhereInput[] | null;
  brand?: BrandWhereInput | null;
  brandId?: NullableStringFilter | null;
  createdAt?: DateTimeFilter | null;
  email?: NullableStringFilter | null;
  id?: StringFilter | null;
  NOT?: AutomaticReportRequestAgentEmailWhereInput[] | null;
  OR?: AutomaticReportRequestAgentEmailWhereInput[] | null;
  updatedAt?: DateTimeFilter | null;
}

export interface BUContactInput {
  email: string;
  idBrand: string;
  name: string;
  phoneNumber: string;
}

export interface BUContactInputWithId {
  email: string;
  id: string;
  idBrand: string;
  name: string;
  phoneNumber: string;
}

export interface BatchCronJobFilter {
  every?: BatchCronJobWhereInput | null;
  none?: BatchCronJobWhereInput | null;
  some?: BatchCronJobWhereInput | null;
}

export interface BatchCronJobWhereInput {
  AND?: BatchCronJobWhereInput[] | null;
  createdAt?: DateTimeFilter | null;
  first?: IntFilter | null;
  id?: StringFilter | null;
  last?: IntFilter | null;
  lastSuccessStoreId?: NullableStringFilter | null;
  NOT?: BatchCronJobWhereInput[] | null;
  onProcessStore?: StoreFilter | null;
  OR?: BatchCronJobWhereInput[] | null;
  status?: CronStatus | null;
  successfulStore?: StoreFilter | null;
  updatedAt?: DateTimeFilter | null;
  year?: IntFilter | null;
}

export interface BooleanFilter {
  equals?: boolean | null;
  not?: boolean | null;
}

export interface BrandImageFilter {
  every?: BrandImageWhereInput | null;
  none?: BrandImageWhereInput | null;
  some?: BrandImageWhereInput | null;
}

export interface BrandImageWhereInput {
  AND?: BrandImageWhereInput[] | null;
  brand?: BrandWhereInput | null;
  brandId?: StringFilter | null;
  brandPage?: BrandPageWhereInput | null;
  createdAt?: DateTimeFilter | null;
  id?: StringFilter | null;
  imageUrl?: StringFilter | null;
  name?: StringFilter | null;
  NOT?: BrandImageWhereInput[] | null;
  OR?: BrandImageWhereInput[] | null;
  updatedAt?: DateTimeFilter | null;
}

export interface BrandPageWhereInput {
  about?: NullableStringFilter | null;
  AND?: BrandPageWhereInput[] | null;
  brand?: BrandWhereInput | null;
  brandId?: StringFilter | null;
  brandRange?: BrandRangeFilter | null;
  createdAt?: DateTimeFilter | null;
  headerImage?: NullableStringFilter | null;
  id?: StringFilter | null;
  logoLibrary?: BrandImageWhereInput | null;
  logoLibraryId?: NullableStringFilter | null;
  name?: StringFilter | null;
  NOT?: BrandPageWhereInput[] | null;
  OR?: BrandPageWhereInput[] | null;
  status?: StatusActive | null;
  updatedAt?: DateTimeFilter | null;
}

export interface BrandRangeFilter {
  every?: BrandRangeWhereInput | null;
  none?: BrandRangeWhereInput | null;
  some?: BrandRangeWhereInput | null;
}

export interface BrandRangeWhereInput {
  AND?: BrandRangeWhereInput[] | null;
  availableIn?: StringFilter | null;
  brandPage?: BrandPageWhereInput | null;
  brandPageId?: StringFilter | null;
  createdAt?: DateTimeFilter | null;
  description?: StringFilter | null;
  id?: StringFilter | null;
  image?: NullableStringFilter | null;
  liveDate?: DateTimeFilter | null;
  name?: StringFilter | null;
  NOT?: BrandRangeWhereInput[] | null;
  OR?: BrandRangeWhereInput[] | null;
  rangeId?: StringFilter | null;
  updatedAt?: DateTimeFilter | null;
}

export interface BrandWhereInput {
  admin?: AdminWhereInput | null;
  AND?: BrandWhereInput[] | null;
  AutomaticReportRequestAgentEmail?: AutomaticReportRequestAgentEmailFilter | null;
  brandId?: StringFilter | null;
  brandPage?: BrandPageWhereInput | null;
  businessUnitContact?: BusinessUnitContactWhereInput | null;
  businessUnitContactId?: NullableStringFilter | null;
  cardSlider?: CardSliderFilter | null;
  competition?: CompetitionFilter | null;
  createdAt?: DateTimeFilter | null;
  dataDraftOrder?: DataDraftOrderFilter | null;
  document?: DocumentFilter | null;
  endDate?: NullableDateTimeFilter | null;
  evenListing?: EventFilter | null;
  id?: StringFilter | null;
  logos?: BrandImageFilter | null;
  LoyaltyPointHistory?: LoyaltyPointHistoryFilter | null;
  name?: StringFilter | null;
  news?: NewsFilter | null;
  NOT?: BrandWhereInput[] | null;
  OR?: BrandWhereInput[] | null;
  pointRate?: FloatFilter | null;
  promotion?: PromotionFilter | null;
  RequestAgent?: RequestAgentFilter | null;
  startDate?: NullableDateTimeFilter | null;
  status?: StatusActive | null;
  updatedAt?: DateTimeFilter | null;
}

export interface BulkAgentFilter {
  every?: BulkAgentWhereInput | null;
  none?: BulkAgentWhereInput | null;
  some?: BulkAgentWhereInput | null;
}

export interface BulkAgentWhereInput {
  admin?: AdminWhereInput | null;
  adminId?: StringFilter | null;
  AND?: BulkAgentWhereInput[] | null;
  countInvalid?: IntFilter | null;
  countValid?: IntFilter | null;
  createdAt?: DateTimeFilter | null;
  details?: NullableStringFilter | null;
  fileName?: StringFilter | null;
  id?: StringFilter | null;
  invalidLink?: NullableStringFilter | null;
  link?: NullableStringFilter | null;
  NOT?: BulkAgentWhereInput[] | null;
  OR?: BulkAgentWhereInput[] | null;
  status?: StatusBulkPoint | null;
  temporaryGivenAgent?: TemporaryGivenAgentFilter | null;
  updatedAt?: DateTimeFilter | null;
}

export interface BulkPointFilter {
  every?: BulkPointWhereInput | null;
  none?: BulkPointWhereInput | null;
  some?: BulkPointWhereInput | null;
}

export interface BulkPointWhereInput {
  admin?: AdminWhereInput | null;
  adminId?: StringFilter | null;
  AND?: BulkPointWhereInput[] | null;
  countInvalid?: IntFilter | null;
  countValid?: IntFilter | null;
  createdAt?: DateTimeFilter | null;
  details?: NullableStringFilter | null;
  fileName?: StringFilter | null;
  id?: StringFilter | null;
  invalidLink?: NullableStringFilter | null;
  link?: NullableStringFilter | null;
  NOT?: BulkPointWhereInput[] | null;
  OR?: BulkPointWhereInput[] | null;
  status?: StatusBulkPoint | null;
  temporaryGivenPoint?: TemporaryGivenPointFilter | null;
  updatedAt?: DateTimeFilter | null;
}

export interface BusinessContactBrandInput {
  create?: createBUContact | null;
  delete?: deleteBUContact | null;
  update?: updateBUContact | null;
}

export interface BusinessUnitContactWhereInput {
  AND?: BusinessUnitContactWhereInput[] | null;
  brand?: BrandWhereInput | null;
  email?: StringFilter | null;
  id?: StringFilter | null;
  name?: StringFilter | null;
  News?: NewsFilter | null;
  NOT?: BusinessUnitContactWhereInput[] | null;
  OR?: BusinessUnitContactWhereInput[] | null;
  phoneNumber?: StringFilter | null;
  Promotion?: PromotionFilter | null;
  RequestAgent?: RequestAgentFilter | null;
  requestAgentReportActiveSendEmail?: BooleanFilter | null;
}

export interface CardSliderAppUserFilter {
  every?: CardSliderAppUserWhereInput | null;
  none?: CardSliderAppUserWhereInput | null;
  some?: CardSliderAppUserWhereInput | null;
}

export interface CardSliderAppUserWhereInput {
  AND?: CardSliderAppUserWhereInput[] | null;
  CardSlider?: CardSliderFilter | null;
  id?: StringFilter | null;
  name?: StringFilter | null;
  NOT?: CardSliderAppUserWhereInput[] | null;
  OR?: CardSliderAppUserWhereInput[] | null;
}

export interface CardSliderCityFilter {
  every?: CardSliderCityWhereInput | null;
  none?: CardSliderCityWhereInput | null;
  some?: CardSliderCityWhereInput | null;
}

export interface CardSliderCityWhereInput {
  AND?: CardSliderCityWhereInput[] | null;
  CardSlider?: CardSliderFilter | null;
  id?: StringFilter | null;
  name?: StringFilter | null;
  NOT?: CardSliderCityWhereInput[] | null;
  OR?: CardSliderCityWhereInput[] | null;
}

export interface CardSliderFilter {
  every?: CardSliderWhereInput | null;
  none?: CardSliderWhereInput | null;
  some?: CardSliderWhereInput | null;
}

export interface CardSliderOrderBy {
  order: OrderByArg;
  type: CardSliderOrderByType;
}

export interface CardSliderRegionFilter {
  every?: CardSliderRegionWhereInput | null;
  none?: CardSliderRegionWhereInput | null;
  some?: CardSliderRegionWhereInput | null;
}

export interface CardSliderRegionWhereInput {
  AND?: CardSliderRegionWhereInput[] | null;
  CardSlider?: CardSliderFilter | null;
  id?: StringFilter | null;
  name?: StringFilter | null;
  NOT?: CardSliderRegionWhereInput[] | null;
  OR?: CardSliderRegionWhereInput[] | null;
}

export interface CardSliderWhereInput {
  active?: BooleanFilter | null;
  AND?: CardSliderWhereInput[] | null;
  appUser?: CardSliderAppUserFilter | null;
  brand?: BrandWhereInput | null;
  brandId?: NullableStringFilter | null;
  city?: CardSliderCityFilter | null;
  createdAt?: DateTimeFilter | null;
  endLiveDate?: DateTimeFilter | null;
  externalLink?: NullableStringFilter | null;
  id?: StringFilter | null;
  image?: NullableStringFilter | null;
  liveDate?: DateTimeFilter | null;
  name?: StringFilter | null;
  NOT?: CardSliderWhereInput[] | null;
  numberOrder?: IntFilter | null;
  OR?: CardSliderWhereInput[] | null;
  promotionLink?: PromotionWhereInput | null;
  promotionLinkId?: NullableStringFilter | null;
  region?: CardSliderRegionFilter | null;
  slug?: StringFilter | null;
  status?: ArchiveStatus | null;
  timeActive?: Time | null;
  updatedAt?: DateTimeFilter | null;
}

export interface CompetitionFilter {
  every?: CompetitionWhereInput | null;
  none?: CompetitionWhereInput | null;
  some?: CompetitionWhereInput | null;
}

export interface CompetitionOrderBy {
  order: OrderByArg;
  type: CompetitionOrderByType;
}

export interface CompetitionSocialMediaCreateInput {
  link: string;
  socialMediaType?: MediaSocial | null;
}

export interface CompetitionSocialMediaDeleteInput {
  id: string;
}

export interface CompetitionSocialMediaFilter {
  every?: CompetitionSocialMediaWhereInput | null;
  none?: CompetitionSocialMediaWhereInput | null;
  some?: CompetitionSocialMediaWhereInput | null;
}

export interface CompetitionSocialMediaInput {
  create?: CompetitionSocialMediaCreateInput[] | null;
  delete?: CompetitionSocialMediaDeleteInput[] | null;
  update?: CompetitionSocialMediaUpdateInput[] | null;
}

export interface CompetitionSocialMediaUpdateInput {
  id: string;
  link: string;
  socialMediaType?: MediaSocial | null;
}

export interface CompetitionSocialMediaWhereInput {
  AND?: CompetitionSocialMediaWhereInput[] | null;
  competition?: CompetitionWhereInput | null;
  competitionId?: StringFilter | null;
  createdAt?: DateTimeFilter | null;
  id?: StringFilter | null;
  link?: StringFilter | null;
  NOT?: CompetitionSocialMediaWhereInput[] | null;
  OR?: CompetitionSocialMediaWhereInput[] | null;
  socialMediaType?: MediaSocial | null;
  updatedAt?: DateTimeFilter | null;
}

export interface CompetitionWhereInput {
  AND?: CompetitionWhereInput[] | null;
  appUser?: StringFilter | null;
  brand?: BrandWhereInput | null;
  brandId?: NullableStringFilter | null;
  city?: StringFilter | null;
  closeDate?: DateTimeFilter | null;
  createdAt?: DateTimeFilter | null;
  dedicatedPageImage?: NullableStringFilter | null;
  desc?: NullableStringFilter | null;
  descSalesman?: NullableStringFilter | null;
  endLiveDate?: DateTimeFilter | null;
  eventDate?: DateTimeFilter | null;
  externalButton?: NullableStringFilter | null;
  externalLink?: NullableStringFilter | null;
  formLink?: FormCompetitionWhereInput | null;
  formLinkId?: NullableIntFilter | null;
  id?: StringFilter | null;
  image?: NullableStringFilter | null;
  liveDate?: DateTimeFilter | null;
  loyaltyPointHistory?: LoyaltyPointHistoryFilter | null;
  medsos?: CompetitionSocialMediaFilter | null;
  name?: StringFilter | null;
  NOT?: CompetitionWhereInput[] | null;
  OR?: CompetitionWhereInput[] | null;
  region?: StringFilter | null;
  slug?: StringFilter | null;
  status?: ArchiveStatus | null;
  updatedAt?: DateTimeFilter | null;
  urlDeeplinkSalesman?: NullableStringFilter | null;
  winners?: WinnersFilter | null;
}

export interface CountRange {
  countFrom: number;
  countTo: number;
}

export interface DataDraftOrderFilter {
  every?: DataDraftOrderWhereInput | null;
  none?: DataDraftOrderWhereInput | null;
  some?: DataDraftOrderWhereInput | null;
}

export interface DataDraftOrderWhereInput {
  AND?: DataDraftOrderWhereInput[] | null;
  brand?: BrandWhereInput | null;
  brandId?: StringFilter | null;
  createdAt?: DateTimeFilter | null;
  draftOrder?: DraftOrderWhereInput | null;
  draftOrderId?: StringFilter | null;
  id?: StringFilter | null;
  NOT?: DataDraftOrderWhereInput[] | null;
  note?: NullableStringFilter | null;
  OR?: DataDraftOrderWhereInput[] | null;
  products?: ProductFilter | null;
  promotion?: PromotionFilter | null;
  updatedAt?: DateTimeFilter | null;
}

export interface DateFilter {
  endDate: any;
  startDate: any;
}

export interface DateTimeFilter {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  lte?: any | null;
  not?: any | null;
  notIn?: any[] | null;
}

export interface DeleteBUContact {
  id: string[];
}

export interface DeleteWinners {
  id: string;
}

export interface DocumentAdvanceSearchOrderByInput {
  order: OrderByArg;
  type: DocumentAdvanceSearchOrderByType;
}

export interface DocumentFilter {
  every?: DocumentWhereInput | null;
  none?: DocumentWhereInput | null;
  some?: DocumentWhereInput | null;
}

export interface DocumentWhereInput {
  AND?: DocumentWhereInput[] | null;
  brand?: BrandWhereInput | null;
  brandId?: StringFilter | null;
  createdAt?: DateTimeFilter | null;
  documentName?: StringFilter | null;
  id?: StringFilter | null;
  liveDate?: DateTimeFilter | null;
  NOT?: DocumentWhereInput[] | null;
  OR?: DocumentWhereInput[] | null;
  status?: ArchiveStatus | null;
  thumbnail?: StringFilter | null;
  thumbnailOrientation?: Orientation | null;
  updatedAt?: DateTimeFilter | null;
  url?: StringFilter | null;
}

export interface DokuRedeemOrderFilter {
  every?: DokuRedeemOrderWhereInput | null;
  none?: DokuRedeemOrderWhereInput | null;
  some?: DokuRedeemOrderWhereInput | null;
}

export interface DokuRedeemOrderWhereInput {
  amount?: IntFilter | null;
  AND?: DokuRedeemOrderWhereInput[] | null;
  createdAt?: DateTimeFilter | null;
  deviceId?: NullableStringFilter | null;
  id?: StringFilter | null;
  isRefunded?: BooleanFilter | null;
  LoyaltyPointHistory?: LoyaltyPointHistoryFilter | null;
  NOT?: DokuRedeemOrderWhereInput[] | null;
  OR?: DokuRedeemOrderWhereInput[] | null;
  refundReason?: NullableStringFilter | null;
  sendTrxId?: StringFilter | null;
  transactionId?: StringFilter | null;
  updatedAt?: DateTimeFilter | null;
  user?: UserWhereInput | null;
  userId?: StringFilter | null;
}

export interface DraftOrderFilter {
  every?: DraftOrderWhereInput | null;
  none?: DraftOrderWhereInput | null;
  some?: DraftOrderWhereInput | null;
}

export interface DraftOrderWhereInput {
  AND?: DraftOrderWhereInput[] | null;
  brandId?: StringFilter | null;
  createdAt?: DateTimeFilter | null;
  dataDraftOrder?: DataDraftOrderFilter | null;
  id?: StringFilter | null;
  NOT?: DraftOrderWhereInput[] | null;
  OR?: DraftOrderWhereInput[] | null;
  rangeId?: StringFilter | null;
  updatedAt?: DateTimeFilter | null;
  user?: UserWhereInput | null;
  userId?: StringFilter | null;
}

export interface EmailRequestAgentCreateInput {
  brandId: string;
  email: string;
}

export interface EmailRequestAgentDeleteInput {
  id: string;
}

export interface EmailRequestAgentInput {
  create?: EmailRequestAgentCreateInput[] | null;
  delete?: EmailRequestAgentDeleteInput[] | null;
  update?: EmailRequestAgentUpdateInput[] | null;
}

export interface EmailRequestAgentUpdateInput {
  brandId: string;
  email: string;
  id: string;
}

export interface EventFilter {
  every?: EventWhereInput | null;
  none?: EventWhereInput | null;
  some?: EventWhereInput | null;
}

export interface EventOrderBy {
  order: OrderByArg;
  type: EventOrderByType;
}

export interface EventWhereInput {
  AND?: EventWhereInput[] | null;
  appUser?: StringFilter | null;
  brand?: BrandWhereInput | null;
  brandId?: NullableStringFilter | null;
  city?: StringFilter | null;
  createdAt?: DateTimeFilter | null;
  dedicatedPageImage?: NullableStringFilter | null;
  desc?: NullableStringFilter | null;
  descSalesman?: NullableStringFilter | null;
  endLiveDate?: DateTimeFilter | null;
  eventDate?: DateTimeFilter | null;
  externalButton?: NullableStringFilter | null;
  externalLink?: NullableStringFilter | null;
  formLink?: FormEventWhereInput | null;
  formLinkId?: NullableIntFilter | null;
  id?: StringFilter | null;
  image?: NullableStringFilter | null;
  liveDate?: DateTimeFilter | null;
  LoyaltyPointHistory?: LoyaltyPointHistoryFilter | null;
  name?: StringFilter | null;
  NOT?: EventWhereInput[] | null;
  OR?: EventWhereInput[] | null;
  region?: StringFilter | null;
  registrationDeadline?: NullableDateTimeFilter | null;
  slug?: StringFilter | null;
  status?: ArchiveStatus | null;
  updatedAt?: DateTimeFilter | null;
  urlDeeplinkSalesman?: NullableStringFilter | null;
  venue?: StringFilter | null;
}

export interface ExternalAgentWhereInput {
  agentCode?: StringFilter | null;
  AND?: ExternalAgentWhereInput[] | null;
  city?: StringFilter | null;
  createdAt?: DateTimeFilter | null;
  email?: NullableStringFilter | null;
  id?: StringFilter | null;
  name?: StringFilter | null;
  NOT?: ExternalAgentWhereInput[] | null;
  OR?: ExternalAgentWhereInput[] | null;
  phoneNumber?: StringFilter | null;
  store?: StoreFilter | null;
  updatedAt?: DateTimeFilter | null;
}

export interface FilterLoyaltyHistoryInput {
  endDate?: any | null;
  phoneNumber?: string | null;
  redeemSalesmanCode?: string | null;
  redeemStatus?: RedeemStatus | null;
  source?: LoyaltySource | null;
  startDate?: any | null;
  storeCode?: string | null;
  storeName?: string | null;
  userName?: string | null;
}

export interface FilterStoreInput {
  address?: string | null;
  cityId?: string | null;
  districtId?: string | null;
  provinceId?: string | null;
  storeCategoryId?: string | null;
  storeCode?: string | null;
  storeEmail?: string | null;
  storeName?: string | null;
  storePhoneNumber?: string | null;
  urbanVillageId?: string | null;
}

export interface FilterStoreOwnerSalesReferralCodeInput {
  dateOfFiling?: DateFilter | null;
  phoneNumber?: string | null;
  salesmanReferralCode?: string | null;
  storeCode?: string | null;
  storeName?: string | null;
  verificationStatus?: boolean | null;
  verifiedDate?: DateFilter | null;
}

export interface FilterUserInput {
  accNumber?: string | null;
  email?: string | null;
  name?: string | null;
  phoneNumber?: string | null;
}

export interface FloatFilter {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: number | null;
  notIn?: number[] | null;
}

export interface FormCompetitionOrderBy {
  order: OrderByArg;
  type: FormCompetitionOrderByType;
}

export interface FormCompetitionWhereInput {
  AND?: FormCompetitionWhereInput[] | null;
  competition?: CompetitionWhereInput | null;
  createdAt?: DateTimeFilter | null;
  id?: IntFilter | null;
  image?: NullableStringFilter | null;
  link?: StringFilter | null;
  name?: StringFilter | null;
  NOT?: FormCompetitionWhereInput[] | null;
  OR?: FormCompetitionWhereInput[] | null;
  prize?: NullableStringFilter | null;
  prizeValue?: IntFilter | null;
  slug?: StringFilter | null;
  status?: ArchiveStatus | null;
  submitFormCompetition?: SubmitFormCompetitionFilter | null;
  terms?: NullableStringFilter | null;
  updatedAt?: DateTimeFilter | null;
}

export interface FormCompetitionWhereUniqueInput {
  id?: number | null;
  link?: string | null;
}

export interface FormEventOrderBy {
  order: OrderByArg;
  type: FormEventOrderByType;
}

export interface FormEventWhereInput {
  address?: NullableStringFilter | null;
  AND?: FormEventWhereInput[] | null;
  createdAt?: DateTimeFilter | null;
  event?: EventWhereInput | null;
  id?: IntFilter | null;
  latitude?: NullableStringFilter | null;
  link?: StringFilter | null;
  longitude?: NullableStringFilter | null;
  maxParticipant?: NullableIntFilter | null;
  name?: StringFilter | null;
  NOT?: FormEventWhereInput[] | null;
  OR?: FormEventWhereInput[] | null;
  slug?: StringFilter | null;
  status?: ArchiveStatus | null;
  submitFormEvent?: SubmitFormEventFilter | null;
  timeEvent?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  venue?: StringFilter | null;
}

export interface FormEventWhereUniqueInput {
  id?: number | null;
  link?: string | null;
}

export interface IntFilter {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: number | null;
  notIn?: number[] | null;
}

export interface LogChangePhoneNumberFilter {
  every?: LogChangePhoneNumberWhereInput | null;
  none?: LogChangePhoneNumberWhereInput | null;
  some?: LogChangePhoneNumberWhereInput | null;
}

export interface LogChangePhoneNumberWhereInput {
  AND?: LogChangePhoneNumberWhereInput[] | null;
  createdAt?: DateTimeFilter | null;
  dataNumber?: StringFilter | null;
  id?: StringFilter | null;
  newPhoneNumber?: StringFilter | null;
  NOT?: LogChangePhoneNumberWhereInput[] | null;
  oldPhoneNumber?: StringFilter | null;
  OR?: LogChangePhoneNumberWhereInput[] | null;
  updatedAt?: DateTimeFilter | null;
  user?: UserWhereInput | null;
  userId?: StringFilter | null;
}

export interface LogKycSalesmanCodeFilter {
  every?: LogKycSalesmanCodeWhereInput | null;
  none?: LogKycSalesmanCodeWhereInput | null;
  some?: LogKycSalesmanCodeWhereInput | null;
}

export interface LogKycSalesmanCodeWhereInput {
  AND?: LogKycSalesmanCodeWhereInput[] | null;
  createdAt?: DateTimeFilter | null;
  id?: StringFilter | null;
  NOT?: LogKycSalesmanCodeWhereInput[] | null;
  OR?: LogKycSalesmanCodeWhereInput[] | null;
  requestVerification?: RequestVerificationWhereInput | null;
  requestVerificationId?: StringFilter | null;
  salesmanReferralCode?: NullableStringFilter | null;
  status?: StatusVerify | null;
  updatedAt?: DateTimeFilter | null;
}

export interface LogRequestVerificationFilter {
  every?: LogRequestVerificationWhereInput | null;
  none?: LogRequestVerificationWhereInput | null;
  some?: LogRequestVerificationWhereInput | null;
}

export interface LogRequestVerificationWhereInput {
  admin?: StringFilter | null;
  AND?: LogRequestVerificationWhereInput[] | null;
  createdAt?: DateTimeFilter | null;
  id?: StringFilter | null;
  logData?: StringFilter | null;
  nextAction?: NullableStringFilter | null;
  NOT?: LogRequestVerificationWhereInput[] | null;
  note?: StringFilter | null;
  OR?: LogRequestVerificationWhereInput[] | null;
  reason?: NullableStringFilter | null;
  requestVerification?: RequestVerificationWhereInput | null;
  requestVerificationId?: StringFilter | null;
  status?: StatusVerify | null;
  type?: TypeVerify | null;
  unverifType?: NullableStringFilter | null;
  updatedAt?: DateTimeFilter | null;
  validasiToko?: NullableStringFilter | null;
  validVisitation?: ValidVisitation | null;
  verificationResult?: NullableStringFilter | null;
}

export interface LogRollBackVerifyStoreFilter {
  every?: LogRollBackVerifyStoreWhereInput | null;
  none?: LogRollBackVerifyStoreWhereInput | null;
  some?: LogRollBackVerifyStoreWhereInput | null;
}

export interface LogRollBackVerifyStoreWhereInput {
  admin?: AdminWhereInput | null;
  adminId?: StringFilter | null;
  AND?: LogRollBackVerifyStoreWhereInput[] | null;
  createdAt?: DateTimeFilter | null;
  id?: StringFilter | null;
  newStoreManagerCode?: NullableStringFilter | null;
  newStoreManagerId?: NullableStringFilter | null;
  NOT?: LogRollBackVerifyStoreWhereInput[] | null;
  oldStoreManagerCode?: NullableStringFilter | null;
  oldStoreManagerId?: NullableStringFilter | null;
  OR?: LogRollBackVerifyStoreWhereInput[] | null;
  store?: StoreWhereInput | null;
  storeId?: StringFilter | null;
  type?: TypeRollBack | null;
  updatedAt?: DateTimeFilter | null;
}

export interface LoyaltyActivityWhereInput {
  AND?: LoyaltyActivityWhereInput[] | null;
  createdAt?: DateTimeFilter | null;
  desc?: NullableStringFilter | null;
  id?: StringFilter | null;
  internalName?: StringFilter | null;
  loyaltyPointHistory?: LoyaltyPointHistoryFilter | null;
  name?: StringFilter | null;
  NOT?: LoyaltyActivityWhereInput[] | null;
  OR?: LoyaltyActivityWhereInput[] | null;
  point?: IntFilter | null;
  updatedAt?: DateTimeFilter | null;
}

export interface LoyaltyMissionPointWhereInput {
  active?: BooleanFilter | null;
  AND?: LoyaltyMissionPointWhereInput[] | null;
  category?: MissionCategory | null;
  createdAt?: DateTimeFilter | null;
  endDate?: DateTimeFilter | null;
  id?: StringFilter | null;
  loyaltyPointHistory?: LoyaltyPointHistoryFilter | null;
  missionActivityLog?: MissionActivityLogFilter | null;
  missionId?: StringFilter | null;
  name?: StringFilter | null;
  NOT?: LoyaltyMissionPointWhereInput[] | null;
  OR?: LoyaltyMissionPointWhereInput[] | null;
  point?: IntFilter | null;
  startDate?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
}

export interface LoyaltyPointHistoryFilter {
  every?: LoyaltyPointHistoryWhereInput | null;
  none?: LoyaltyPointHistoryWhereInput | null;
  some?: LoyaltyPointHistoryWhereInput | null;
}

export interface LoyaltyPointHistoryWhereInput {
  activityName?: StringFilter | null;
  AND?: LoyaltyPointHistoryWhereInput[] | null;
  balancePoint?: FloatFilter | null;
  brand?: BrandWhereInput | null;
  brandId?: NullableStringFilter | null;
  competition?: CompetitionWhereInput | null;
  competitionId?: NullableStringFilter | null;
  createdAt?: DateTimeFilter | null;
  date?: DateTimeFilter | null;
  dokuOrderReferenceId?: NullableStringFilter | null;
  dokuRedeemOrder?: DokuRedeemOrderWhereInput | null;
  dokuRedeemOrderId?: NullableStringFilter | null;
  event?: EventWhereInput | null;
  eventId?: NullableStringFilter | null;
  id?: StringFilter | null;
  isRefunded?: BooleanFilter | null;
  loyaltyActivity?: LoyaltyActivityWhereInput | null;
  loyaltyActivityId?: NullableStringFilter | null;
  loyaltyMissionPoint?: LoyaltyMissionPointWhereInput | null;
  loyaltyMissionPointId?: NullableStringFilter | null;
  lumpSumType?: LumpSumType | null;
  monetaryValue?: NullableFloatFilter | null;
  NOT?: LoyaltyPointHistoryWhereInput[] | null;
  note?: NullableStringFilter | null;
  OR?: LoyaltyPointHistoryWhereInput[] | null;
  point?: FloatFilter | null;
  promotion?: PromotionWhereInput | null;
  promotionId?: NullableStringFilter | null;
  redeemSalesmanCode?: NullableStringFilter | null;
  referenceIdLoyalty?: NullableStringFilter | null;
  source?: LoyaltySource | null;
  store?: StoreWhereInput | null;
  storeId?: StringFilter | null;
  tadaOrderReferenceId?: NullableStringFilter | null;
  tadaRedeemOrder?: TadaRedeemOrderWhereInput | null;
  tadaRedeemOrderId?: NullableStringFilter | null;
  temporaryGivenPoint?: TemporaryGivenPointWhereInput | null;
  temporaryGivenPointId?: NullableStringFilter | null;
  tonase?: NullableFloatFilter | null;
  transactionNumber?: NullableStringFilter | null;
  type?: HistoryType | null;
  updatedAt?: DateTimeFilter | null;
}

export interface MissionActivityHistoryFilter {
  every?: MissionActivityHistoryWhereInput | null;
  none?: MissionActivityHistoryWhereInput | null;
  some?: MissionActivityHistoryWhereInput | null;
}

export interface MissionActivityHistoryWhereInput {
  admin?: AdminWhereInput | null;
  adminId?: StringFilter | null;
  AND?: MissionActivityHistoryWhereInput[] | null;
  createdAt?: DateTimeFilter | null;
  endDate?: DateTimeFilter | null;
  id?: StringFilter | null;
  NOT?: MissionActivityHistoryWhereInput[] | null;
  OR?: MissionActivityHistoryWhereInput[] | null;
  recentStatus?: BooleanFilter | null;
  startDate?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
}

export interface MissionActivityLogFilter {
  every?: MissionActivityLogWhereInput | null;
  none?: MissionActivityLogWhereInput | null;
  some?: MissionActivityLogWhereInput | null;
}

export interface MissionActivityLogWhereInput {
  AND?: MissionActivityLogWhereInput[] | null;
  createdAt?: DateTimeFilter | null;
  finishedDate?: NullableDateTimeFilter | null;
  id?: StringFilter | null;
  loyaltyMissionPoint?: LoyaltyMissionPointWhereInput | null;
  loyaltyMissionPointId?: StringFilter | null;
  NOT?: MissionActivityLogWhereInput[] | null;
  OR?: MissionActivityLogWhereInput[] | null;
  store?: StoreWhereInput | null;
  storeId?: StringFilter | null;
  topUpDate?: NullableDateTimeFilter | null;
  topUpStatus?: BooleanFilter | null;
  updatedAt?: DateTimeFilter | null;
  user?: UserWhereInput | null;
  userId?: StringFilter | null;
}

export interface MissionTopupRewardDetailFilter {
  every?: MissionTopupRewardDetailWhereInput | null;
  none?: MissionTopupRewardDetailWhereInput | null;
  some?: MissionTopupRewardDetailWhereInput | null;
}

export interface MissionTopupRewardDetailWhereInput {
  AND?: MissionTopupRewardDetailWhereInput[] | null;
  createdAt?: DateTimeFilter | null;
  endDatePeriod?: StringFilter | null;
  id?: StringFilter | null;
  missionId?: StringFilter | null;
  missionTopupReward?: MissionTopupRewardWhereInput | null;
  missionTopupRewardId?: StringFilter | null;
  no?: IntFilter | null;
  NOT?: MissionTopupRewardDetailWhereInput[] | null;
  note?: NullableStringFilter | null;
  OR?: MissionTopupRewardDetailWhereInput[] | null;
  startDatePeriod?: StringFilter | null;
  statusProgress?: StatusProgressTempGivenPoint | null;
  storeCode?: StringFilter | null;
  updatedAt?: DateTimeFilter | null;
  valid?: BooleanFilter | null;
}

export interface MissionTopupRewardFilter {
  every?: MissionTopupRewardWhereInput | null;
  none?: MissionTopupRewardWhereInput | null;
  some?: MissionTopupRewardWhereInput | null;
}

export interface MissionTopupRewardWhereInput {
  admin?: AdminWhereInput | null;
  adminId?: StringFilter | null;
  AND?: MissionTopupRewardWhereInput[] | null;
  countInvalid?: IntFilter | null;
  countValid?: IntFilter | null;
  createdAt?: DateTimeFilter | null;
  detail?: NullableStringFilter | null;
  fileName?: StringFilter | null;
  id?: StringFilter | null;
  invalidLink?: NullableStringFilter | null;
  link?: NullableStringFilter | null;
  missionTopupRewardDetail?: MissionTopupRewardDetailFilter | null;
  NOT?: MissionTopupRewardWhereInput[] | null;
  OR?: MissionTopupRewardWhereInput[] | null;
  status?: StatusBulkPoint | null;
  updatedAt?: DateTimeFilter | null;
  uploadDate?: DateTimeFilter | null;
}

export interface NewsFilter {
  every?: NewsWhereInput | null;
  none?: NewsWhereInput | null;
  some?: NewsWhereInput | null;
}

export interface NewsOrderBy {
  order: OrderByArg;
  type: NewsOrderByType;
}

export interface NewsWhereInput {
  AND?: NewsWhereInput[] | null;
  appUser?: StringFilter | null;
  brand?: BrandWhereInput | null;
  brandId?: NullableStringFilter | null;
  businessUnitContact?: BusinessUnitContactWhereInput | null;
  businessUnitContactId?: NullableStringFilter | null;
  city?: StringFilter | null;
  clickCount?: IntFilter | null;
  createdAt?: DateTimeFilter | null;
  dedicatedPageImage?: NullableStringFilter | null;
  desc?: StringFilter | null;
  descSalesman?: NullableStringFilter | null;
  endLiveDate?: DateTimeFilter | null;
  externalButton?: NullableStringFilter | null;
  externalLink?: NullableStringFilter | null;
  id?: StringFilter | null;
  image?: NullableStringFilter | null;
  internalLink?: ScreenWhereInput | null;
  internalLinkId?: NullableStringFilter | null;
  liveDate?: DateTimeFilter | null;
  NOT?: NewsWhereInput[] | null;
  OR?: NewsWhereInput[] | null;
  rangeId?: NullableStringFilter | null;
  region?: StringFilter | null;
  slug?: StringFilter | null;
  startDate?: DateTimeFilter | null;
  status?: ArchiveStatus | null;
  title?: StringFilter | null;
  typeLink?: NewsTypeLink | null;
  updatedAt?: DateTimeFilter | null;
  urlDeeplinkSalesman?: NullableStringFilter | null;
}

export interface NullableBooleanFilter {
  equals?: boolean | null;
  not?: boolean | null;
}

export interface NullableDateTimeFilter {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  lte?: any | null;
  not?: any | null;
  notIn?: any[] | null;
}

export interface NullableFloatFilter {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: number | null;
  notIn?: number[] | null;
}

export interface NullableIntFilter {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: number | null;
  notIn?: number[] | null;
}

export interface NullableStringFilter {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  not?: string | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface OrderReferenceFilter {
  every?: OrderReferenceWhereInput | null;
  none?: OrderReferenceWhereInput | null;
  some?: OrderReferenceWhereInput | null;
}

export interface OrderReferenceWhereInput {
  AND?: OrderReferenceWhereInput[] | null;
  createdAt?: DateTimeFilter | null;
  id?: StringFilter | null;
  NOT?: OrderReferenceWhereInput[] | null;
  OR?: OrderReferenceWhereInput[] | null;
  payload?: NullableStringFilter | null;
  referenceId?: StringFilter | null;
  updatedAt?: DateTimeFilter | null;
  user?: UserWhereInput | null;
  userId?: StringFilter | null;
}

export interface OwnerInputData {
  birthDate: any;
  email?: string | null;
  gender: Gender;
  googleSheetFormNumber?: string | null;
  idCardNumber?: string | null;
  idCardType?: IdCardType | null;
  name?: string | null;
  phoneNumber?: string | null;
  yearEstablished?: string | null;
}

export interface PaginationInput {
  first?: number | null;
  last?: number | null;
  skip?: number | null;
}

export interface PopUpFilter {
  every?: PopUpWhereInput | null;
  none?: PopUpWhereInput | null;
  some?: PopUpWhereInput | null;
}

export interface PopUpWhereInput {
  AND?: PopUpWhereInput[] | null;
  brandId?: NullableStringFilter | null;
  city?: NullableStringFilter | null;
  color?: PopUpColor | null;
  conditionBrandId?: NullableStringFilter | null;
  conditionRangeId?: NullableStringFilter | null;
  createdAt?: DateTimeFilter | null;
  endDate?: NullableDateTimeFilter | null;
  externalLink?: NullableStringFilter | null;
  id?: StringFilter | null;
  image?: NullableStringFilter | null;
  internalLink?: ScreenWhereInput | null;
  internalLinkId?: NullableStringFilter | null;
  linkType?: LinkTypePopUp | null;
  name?: StringFilter | null;
  NOT?: PopUpWhereInput[] | null;
  OR?: PopUpWhereInput[] | null;
  page?: ScreenWhereInput | null;
  pageId?: NullableStringFilter | null;
  rangeId?: NullableStringFilter | null;
  region?: NullableStringFilter | null;
  startDate?: NullableDateTimeFilter | null;
  status?: ArchiveStatus | null;
  textLine1?: NullableStringFilter | null;
  textLine2?: NullableStringFilter | null;
  textLine3?: NullableStringFilter | null;
  title?: NullableStringFilter | null;
  updatedAt?: DateTimeFilter | null;
  whatsAppLink?: NullableStringFilter | null;
  who?: NullableStringFilter | null;
}

export interface ProductFilter {
  every?: ProductWhereInput | null;
  none?: ProductWhereInput | null;
  some?: ProductWhereInput | null;
}

export interface ProductWhereInput {
  amount?: IntFilter | null;
  AND?: ProductWhereInput[] | null;
  createdAt?: DateTimeFilter | null;
  dataDraftOrder?: DataDraftOrderWhereInput | null;
  dataDraftOrderId?: NullableStringFilter | null;
  id?: StringFilter | null;
  isFromPromotion?: BooleanFilter | null;
  NOT?: ProductWhereInput[] | null;
  OR?: ProductWhereInput[] | null;
  price?: IntFilter | null;
  productId?: StringFilter | null;
  rangeId?: StringFilter | null;
  unit?: StringFilter | null;
  updatedAt?: DateTimeFilter | null;
}

export interface ProfileWhereInput {
  accNumber?: NullableStringFilter | null;
  agreementStatement?: NullableBooleanFilter | null;
  AND?: ProfileWhereInput[] | null;
  avatar?: NullableStringFilter | null;
  birthDate?: NullableDateTimeFilter | null;
  createdAt?: DateTimeFilter | null;
  email?: NullableStringFilter | null;
  emailVerified?: BooleanFilter | null;
  firstEdit?: BooleanFilter | null;
  gender?: Gender | null;
  id?: StringFilter | null;
  idCardNumber?: NullableStringFilter | null;
  idCardPhoto?: NullableStringFilter | null;
  idCardType?: IdCardType | null;
  name?: StringFilter | null;
  NOT?: ProfileWhereInput[] | null;
  OR?: ProfileWhereInput[] | null;
  reUpload?: NullableBooleanFilter | null;
  slug?: StringFilter | null;
  tadaCityId?: NullableStringFilter | null;
  tadaPostalCode?: NullableStringFilter | null;
  tadaProvinceId?: NullableStringFilter | null;
  updatedAt?: DateTimeFilter | null;
  uploadAt?: NullableDateTimeFilter | null;
  user?: UserWhereInput | null;
  userId?: StringFilter | null;
}

export interface PromotionFilter {
  every?: PromotionWhereInput | null;
  none?: PromotionWhereInput | null;
  some?: PromotionWhereInput | null;
}

export interface PromotionOrderBy {
  order: OrderByArg;
  type: PromotionOrderByType;
}

export interface PromotionWhereInput {
  AND?: PromotionWhereInput[] | null;
  appUser?: StringFilter | null;
  brand?: BrandWhereInput | null;
  brandId?: StringFilter | null;
  businessUnitContact?: BusinessUnitContactWhereInput | null;
  businessUnitContactId?: NullableStringFilter | null;
  cardSlider?: CardSliderFilter | null;
  city?: StringFilter | null;
  createdAt?: DateTimeFilter | null;
  DataDraftOrder?: DataDraftOrderFilter | null;
  desc?: NullableStringFilter | null;
  descSalesman?: NullableStringFilter | null;
  endLiveDate?: DateTimeFilter | null;
  heroImage?: NullableStringFilter | null;
  id?: StringFilter | null;
  liveDate?: DateTimeFilter | null;
  LoyaltyPointHistory?: LoyaltyPointHistoryFilter | null;
  name?: StringFilter | null;
  NOT?: PromotionWhereInput[] | null;
  OR?: PromotionWhereInput[] | null;
  productId?: NullableStringFilter | null;
  productQuantity?: NullableIntFilter | null;
  promotionCode?: NullableStringFilter | null;
  rangeId?: NullableStringFilter | null;
  region?: StringFilter | null;
  sendPushNotifAfterCreate?: BooleanFilter | null;
  showInMobile?: BooleanFilter | null;
  slug?: StringFilter | null;
  status?: ArchiveStatus | null;
  termAndCondition?: StringFilter | null;
  unit?: NullableIntFilter | null;
  updatedAt?: DateTimeFilter | null;
  urlDeeplinkSalesman?: NullableStringFilter | null;
}

export interface ReferralStorePhotoVerifAdvanceInput {
  firstRevalidationSubmitDate?: DateFilter | null;
  managerStoreCode?: string | null;
  phoneNumber?: string | null;
  storeName?: string | null;
  storePhotoSalesmanReferralCode?: string | null;
  storeRequestVerifStatus?: StatusVerify | null;
}

export interface RequestAgentDownloadInputType {
  brandId?: string | null;
  cityId?: string | null;
  newStatus?: NewCmsRequestStatusFilter | null;
  orderBy?: RequestAgentOrderByInput | null;
  provinceId?: string | null;
  registrationDate?: DateFilter | null;
  requestDate?: DateFilter | null;
  status?: CmsRequestStatusFilter | null;
  storeCode?: string | null;
  storeName?: string | null;
}

export interface RequestAgentFilter {
  every?: RequestAgentWhereInput | null;
  none?: RequestAgentWhereInput | null;
  some?: RequestAgentWhereInput | null;
}

export interface RequestAgentOrderByInput {
  adminId?: OrderByArg | null;
  adminVerified?: OrderByArg | null;
  agentId?: OrderByArg | null;
  agentName?: OrderByArg | null;
  agentReassignDate?: OrderByArg | null;
  alreadyReject?: OrderByArg | null;
  brandId?: OrderByArg | null;
  businessUnitContactId?: OrderByArg | null;
  createdAt?: OrderByArg | null;
  id?: OrderByArg | null;
  isCancelledInAction?: OrderByArg | null;
  orderId?: OrderByArg | null;
  rejectedBy?: OrderByArg | null;
  rejectReason?: OrderByArg | null;
  requestFrom?: OrderByArg | null;
  salesmanReferralCode?: OrderByArg | null;
  sameRequestAgent?: OrderByArg | null;
  status?: OrderByArg | null;
  storeId?: OrderByArg | null;
  token?: OrderByArg | null;
  unmatchedDate?: OrderByArg | null;
  updatedAt?: OrderByArg | null;
  userId?: OrderByArg | null;
}

export interface RequestAgentVerificationCreateInput {
  agentId?: string | null;
  agentName: string;
  brandId: string;
}

export interface RequestAgentVerificationInput {
  create?: RequestAgentVerificationCreateInput[] | null;
  update?: RequestAgentVerificationUpdateInput[] | null;
}

export interface RequestAgentVerificationUpdateInput {
  agentId?: string | null;
  agentName: string;
  brandId: string;
  requestAgentId: number;
}

export interface RequestAgentWhereInput {
  admin?: AdminWhereInput | null;
  adminId?: NullableStringFilter | null;
  adminVerified?: BooleanFilter | null;
  agentId?: NullableStringFilter | null;
  agentName?: NullableStringFilter | null;
  agentReassignDate?: NullableDateTimeFilter | null;
  alreadyReject?: BooleanFilter | null;
  AND?: RequestAgentWhereInput[] | null;
  brand?: BrandWhereInput | null;
  brandId?: StringFilter | null;
  businessUnitContact?: BusinessUnitContactWhereInput | null;
  businessUnitContactId?: NullableStringFilter | null;
  createdAt?: DateTimeFilter | null;
  id?: IntFilter | null;
  isCancelledInAction?: BooleanFilter | null;
  NOT?: RequestAgentWhereInput[] | null;
  OR?: RequestAgentWhereInput[] | null;
  orderId?: NullableStringFilter | null;
  rejectedBy?: RejectedBy | null;
  rejectReason?: NullableStringFilter | null;
  requestFrom?: RequestAgentFrom | null;
  salesmanReferralCode?: NullableStringFilter | null;
  sameRequestAgent?: NullableIntFilter | null;
  status?: RequestStatus | null;
  store?: StoreWhereInput | null;
  storeId?: StringFilter | null;
  token?: StringFilter | null;
  unmatchedDate?: NullableDateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  user?: UserWhereInput | null;
  userId?: NullableStringFilter | null;
}

export interface RequestVerificationFilter {
  every?: RequestVerificationWhereInput | null;
  none?: RequestVerificationWhereInput | null;
  some?: RequestVerificationWhereInput | null;
}

export interface RequestVerificationOrderBy {
  order: OrderByArg;
  type: RequestVerificationOrderByType;
}

export interface RequestVerificationWhereInput {
  admin?: AdminWhereInput | null;
  adminId?: NullableStringFilter | null;
  AND?: RequestVerificationWhereInput[] | null;
  claimAt?: NullableDateTimeFilter | null;
  confirmAt?: NullableDateTimeFilter | null;
  confirmBy?: NullableStringFilter | null;
  createdAt?: DateTimeFilter | null;
  id?: StringFilter | null;
  logKycSalesmanCode?: LogKycSalesmanCodeFilter | null;
  logRequestVerification?: LogRequestVerificationFilter | null;
  NOT?: RequestVerificationWhereInput[] | null;
  note?: StringFilter | null;
  OR?: RequestVerificationWhereInput[] | null;
  salesmanReferralCode?: NullableStringFilter | null;
  sendWAMessage?: BooleanFilter | null;
  status?: StatusVerify | null;
  store?: StoreWhereInput | null;
  storeId?: NullableStringFilter | null;
  type?: TypeVerify | null;
  unverifiedReasonData?: UnverifiedReasonDataFilter | null;
  updatedAt?: DateTimeFilter | null;
  user?: UserWhereInput | null;
  userId?: NullableStringFilter | null;
  validasiToko?: NullableStringFilter | null;
  validVisitation?: ValidVisitation | null;
  verifStorePhotoSalesmanCode?: NullableStringFilter | null;
}

export interface ScreenWhereInput {
  AND?: ScreenWhereInput[] | null;
  createdAt?: DateTimeFilter | null;
  id?: StringFilter | null;
  internalLink?: PopUpFilter | null;
  key?: StringFilter | null;
  name?: StringFilter | null;
  news?: NewsFilter | null;
  NOT?: ScreenWhereInput[] | null;
  OR?: ScreenWhereInput[] | null;
  page?: PopUpFilter | null;
  parent?: NullableStringFilter | null;
  type?: AccessScreen | null;
  updatedAt?: DateTimeFilter | null;
}

export interface SocialMediaCreateInput {
  icon: string;
  link: string;
  order: number;
}

export interface SocialMediaDeleteInput {
  id: string;
}

export interface SocialMediaInput {
  create?: SocialMediaCreateInput[] | null;
  delete?: SocialMediaDeleteInput[] | null;
  update?: SocialMediaUpdateInput[] | null;
}

export interface SocialMediaUpdateInput {
  icon: string;
  id: string;
  link: string;
  order: number;
}

export interface StoreFilter {
  every?: StoreWhereInput | null;
  none?: StoreWhereInput | null;
  some?: StoreWhereInput | null;
}

export interface StoreInputData {
  cityId?: string | null;
  districtId?: string | null;
  employee?: SubUserUpdateInput[] | null;
  latitude?: string | null;
  longitude?: string | null;
  ownerPhoneNumber?: string | null;
  postalCode?: string | null;
  provinceId?: string | null;
  referralCode?: string | null;
  rt?: string | null;
  rw?: string | null;
  storeAddress?: string | null;
  storeCategoryId?: string | null;
  storeEmail?: string | null;
  storeName?: string | null;
  storePhoneNumber?: string | null;
  urbanVillageId?: string | null;
  website?: string | null;
}

export interface StoreOrderByInput {
  address?: OrderByArg | null;
  autoCompleteManagerStoreId?: OrderByArg | null;
  cityId?: OrderByArg | null;
  cityName?: OrderByArg | null;
  createdAt?: OrderByArg | null;
  districtId?: OrderByArg | null;
  districtName?: OrderByArg | null;
  externalAgentId?: OrderByArg | null;
  firstRevalidationSubmitDate?: OrderByArg | null;
  id?: OrderByArg | null;
  latestNotOrderingNotification?: OrderByArg | null;
  latitude?: OrderByArg | null;
  longitude?: OrderByArg | null;
  managerStoreCode?: OrderByArg | null;
  managerStoreId?: OrderByArg | null;
  managerStoreTempId?: OrderByArg | null;
  oldManagerStoreCode?: OrderByArg | null;
  oldManagerStoreId?: OrderByArg | null;
  point?: OrderByArg | null;
  postalCode?: OrderByArg | null;
  proofOfBusiness?: OrderByArg | null;
  provinceId?: OrderByArg | null;
  provinceName?: OrderByArg | null;
  reUpload?: OrderByArg | null;
  revalidatedDate?: OrderByArg | null;
  rt?: OrderByArg | null;
  rw?: OrderByArg | null;
  salesReferralCode?: OrderByArg | null;
  slug?: OrderByArg | null;
  storeCategoryId?: OrderByArg | null;
  storeEmail?: OrderByArg | null;
  storeEmailVerified?: OrderByArg | null;
  storeName?: OrderByArg | null;
  storePhoneNumber?: OrderByArg | null;
  storePhotoFront?: OrderByArg | null;
  storePhotoFrontMeta?: OrderByArg | null;
  storePhotoInside?: OrderByArg | null;
  storePhotoInsideMeta?: OrderByArg | null;
  storeVerified?: OrderByArg | null;
  updatedAt?: OrderByArg | null;
  urbanVillageId?: OrderByArg | null;
  urbanVillageName?: OrderByArg | null;
  verifiedDate?: OrderByArg | null;
  verifyAfterRevalidatedDate?: OrderByArg | null;
  website?: OrderByArg | null;
  yearEstablished?: OrderByArg | null;
}

export interface StoreWhereInput {
  address?: NullableStringFilter | null;
  AND?: StoreWhereInput[] | null;
  autoCompleteManagerStoreId?: NullableStringFilter | null;
  cityId?: NullableStringFilter | null;
  cityName?: NullableStringFilter | null;
  createdAt?: DateTimeFilter | null;
  districtId?: NullableStringFilter | null;
  districtName?: NullableStringFilter | null;
  externalAgent?: ExternalAgentWhereInput | null;
  externalAgentId?: NullableStringFilter | null;
  firstRevalidationSubmitDate?: NullableDateTimeFilter | null;
  id?: StringFilter | null;
  latestNotOrderingNotification?: NullableDateTimeFilter | null;
  latitude?: NullableStringFilter | null;
  LogRollBackVerifyStore?: LogRollBackVerifyStoreFilter | null;
  longitude?: NullableStringFilter | null;
  loyaltyPointHistories?: LoyaltyPointHistoryFilter | null;
  managerStoreCode?: NullableStringFilter | null;
  managerStoreId?: NullableStringFilter | null;
  managerStoreTempId?: NullableStringFilter | null;
  missionActivityLog?: MissionActivityLogFilter | null;
  NOT?: StoreWhereInput[] | null;
  oldManagerStoreCode?: NullableStringFilter | null;
  oldManagerStoreId?: NullableStringFilter | null;
  onProcessCron?: BatchCronJobFilter | null;
  OR?: StoreWhereInput[] | null;
  point?: FloatFilter | null;
  postalCode?: NullableStringFilter | null;
  proofOfBusiness?: NullableStringFilter | null;
  provinceId?: NullableStringFilter | null;
  provinceName?: NullableStringFilter | null;
  RequestAgent?: RequestAgentFilter | null;
  requestVerification?: RequestVerificationWhereInput | null;
  reUpload?: NullableBooleanFilter | null;
  revalidatedDate?: NullableDateTimeFilter | null;
  rt?: NullableStringFilter | null;
  rw?: NullableStringFilter | null;
  salesReferralCode?: NullableStringFilter | null;
  slug?: NullableStringFilter | null;
  storeCategoryId?: StringFilter | null;
  storeEmail?: NullableStringFilter | null;
  storeEmailVerified?: BooleanFilter | null;
  storeName?: StringFilter | null;
  storePhoneNumber?: StringFilter | null;
  storePhotoFront?: NullableStringFilter | null;
  storePhotoFrontMeta?: NullableStringFilter | null;
  storePhotoInside?: NullableStringFilter | null;
  storePhotoInsideMeta?: NullableStringFilter | null;
  storeVerified?: BooleanFilter | null;
  successfulCron?: BatchCronJobFilter | null;
  tadaCard?: TadaCardWhereInput | null;
  updatedAt?: DateTimeFilter | null;
  updatedStoreHistory?: UpdatedStoreHistoryFilter | null;
  urbanVillageId?: NullableStringFilter | null;
  urbanVillageName?: NullableStringFilter | null;
  User?: UserFilter | null;
  verifiedDate?: NullableDateTimeFilter | null;
  verifyAfterRevalidatedDate?: NullableDateTimeFilter | null;
  website?: NullableStringFilter | null;
  yearEstablished?: NullableStringFilter | null;
}

export interface StringFilter {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  not?: string | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface SubUserUpdateInput {
  email?: string | null;
  id: string;
  name: string;
  privileges: AccessUser[];
  role?: Role | null;
  status?: StatusType | null;
}

export interface SubmitFormCompetitionFilter {
  every?: SubmitFormCompetitionWhereInput | null;
  none?: SubmitFormCompetitionWhereInput | null;
  some?: SubmitFormCompetitionWhereInput | null;
}

export interface SubmitFormCompetitionWhereInput {
  AND?: SubmitFormCompetitionWhereInput[] | null;
  createdAt?: DateTimeFilter | null;
  formCompetition?: FormCompetitionWhereInput | null;
  formCompetitionId?: NullableIntFilter | null;
  id?: StringFilter | null;
  name?: StringFilter | null;
  NOT?: SubmitFormCompetitionWhereInput[] | null;
  OR?: SubmitFormCompetitionWhereInput[] | null;
  phoneNumber?: StringFilter | null;
  storeName?: StringFilter | null;
  updatedAt?: DateTimeFilter | null;
}

export interface SubmitFormEventFilter {
  every?: SubmitFormEventWhereInput | null;
  none?: SubmitFormEventWhereInput | null;
  some?: SubmitFormEventWhereInput | null;
}

export interface SubmitFormEventWhereInput {
  AND?: SubmitFormEventWhereInput[] | null;
  attendees?: IntFilter | null;
  createdAt?: DateTimeFilter | null;
  formEvent?: FormEventWhereInput | null;
  formEventId?: NullableIntFilter | null;
  id?: StringFilter | null;
  name?: StringFilter | null;
  NOT?: SubmitFormEventWhereInput[] | null;
  OR?: SubmitFormEventWhereInput[] | null;
  phoneNumber?: StringFilter | null;
  storeName?: StringFilter | null;
  updatedAt?: DateTimeFilter | null;
}

export interface TadaCardWhereInput {
  AND?: TadaCardWhereInput[] | null;
  cardNo?: StringFilter | null;
  cardPin?: StringFilter | null;
  createdAt?: DateTimeFilter | null;
  id?: StringFilter | null;
  NOT?: TadaCardWhereInput[] | null;
  OR?: TadaCardWhereInput[] | null;
  store?: StoreWhereInput | null;
  storeId?: StringFilter | null;
  updatedAt?: DateTimeFilter | null;
}

export interface TadaRedeemOrderWhereInput {
  AND?: TadaRedeemOrderWhereInput[] | null;
  categoryItem?: NullableStringFilter | null;
  categoryItemId?: NullableStringFilter | null;
  createdAt?: DateTimeFilter | null;
  cronVerified?: BooleanFilter | null;
  deviceId?: NullableStringFilter | null;
  handlingFee?: NullableIntFilter | null;
  handlingFeePoint?: NullableIntFilter | null;
  id?: StringFilter | null;
  insuraceFee?: NullableIntFilter | null;
  insuraceFeePoint?: NullableIntFilter | null;
  isRefunded?: BooleanFilter | null;
  LoyaltyPointHistory?: LoyaltyPointHistoryFilter | null;
  NOT?: TadaRedeemOrderWhereInput[] | null;
  OR?: TadaRedeemOrderWhereInput[] | null;
  orderNumber?: NullableStringFilter | null;
  pricePerItem?: NullableIntFilter | null;
  pricePointPerItem?: NullableFloatFilter | null;
  processingFee?: NullableIntFilter | null;
  processingFeePoint?: NullableIntFilter | null;
  quantity?: NullableIntFilter | null;
  refundReason?: NullableStringFilter | null;
  shippingFee?: NullableIntFilter | null;
  shippingFeePoint?: NullableIntFilter | null;
  tadaOrderId?: IntFilter | null;
  taxFee?: NullableIntFilter | null;
  taxFeePoint?: NullableIntFilter | null;
  totalPrice?: FloatFilter | null;
  totalPriceRp?: NullableIntFilter | null;
  totalRefunded?: FloatFilter | null;
  typeItem?: TadaItemType | null;
  updatedAt?: DateTimeFilter | null;
  utilityFee?: NullableIntFilter | null;
  utilityFeePoint?: NullableIntFilter | null;
}

export interface TemporaryGivenAgentFilter {
  every?: TemporaryGivenAgentWhereInput | null;
  none?: TemporaryGivenAgentWhereInput | null;
  some?: TemporaryGivenAgentWhereInput | null;
}

export interface TemporaryGivenAgentWhereInput {
  AND?: TemporaryGivenAgentWhereInput[] | null;
  bulkAgent?: BulkAgentWhereInput | null;
  bulkAgentId?: StringFilter | null;
  city?: StringFilter | null;
  createdAt?: DateTimeFilter | null;
  email?: NullableStringFilter | null;
  id?: StringFilter | null;
  name?: StringFilter | null;
  no?: IntFilter | null;
  NOT?: TemporaryGivenAgentWhereInput[] | null;
  OR?: TemporaryGivenAgentWhereInput[] | null;
  phoneNumber?: StringFilter | null;
  status?: StatusGivenPoint | null;
  statusProgress?: StatusProgressTempGivenPoint | null;
  updatedAt?: DateTimeFilter | null;
}

export interface TemporaryGivenPointFilter {
  every?: TemporaryGivenPointWhereInput | null;
  none?: TemporaryGivenPointWhereInput | null;
  some?: TemporaryGivenPointWhereInput | null;
}

export interface TemporaryGivenPointWhereInput {
  action?: StatusAddEarnGivenPoint | null;
  AND?: TemporaryGivenPointWhereInput[] | null;
  brand?: NullableStringFilter | null;
  bulkPoint?: BulkPointWhereInput | null;
  bulkPointId?: StringFilter | null;
  codeStore?: NullableStringFilter | null;
  createdAt?: DateTimeFilter | null;
  endDate?: NullableStringFilter | null;
  id?: StringFilter | null;
  loyaltyPoint?: LoyaltyPointHistoryWhereInput | null;
  name?: NullableStringFilter | null;
  no?: IntFilter | null;
  NOT?: TemporaryGivenPointWhereInput[] | null;
  note?: NullableStringFilter | null;
  OR?: TemporaryGivenPointWhereInput[] | null;
  sendPushNotif?: BooleanFilter | null;
  startDate?: NullableStringFilter | null;
  status?: StatusGivenPoint | null;
  statusProgress?: StatusProgressTempGivenPoint | null;
  storeName?: NullableStringFilter | null;
  tonase?: NullableFloatFilter | null;
  totalPoint?: NullableFloatFilter | null;
  totalTransaction?: NullableFloatFilter | null;
  type?: LumpSumType | null;
  typeId?: NullableStringFilter | null;
  updatedAt?: DateTimeFilter | null;
}

export interface UnverifiedReason {
  nextAction?: string | null;
  otherReason?: string | null;
  phoneType?: PhoneCategory | null;
  reason?: string | null;
  reasonType?: DocumentProblemType | null;
  storePhotoType?: StorePhotoCategory | null;
}

export interface UnverifiedReasonDataFilter {
  every?: UnverifiedReasonDataWhereInput | null;
  none?: UnverifiedReasonDataWhereInput | null;
  some?: UnverifiedReasonDataWhereInput | null;
}

export interface UnverifiedReasonDataWhereInput {
  AND?: UnverifiedReasonDataWhereInput[] | null;
  createdAt?: DateTimeFilter | null;
  documentProblemType?: DocumentProblemType | null;
  id?: StringFilter | null;
  nextAction?: StringFilter | null;
  NOT?: UnverifiedReasonDataWhereInput[] | null;
  OR?: UnverifiedReasonDataWhereInput[] | null;
  otherReason?: NullableStringFilter | null;
  phoneCategory?: PhoneCategory | null;
  problemReason?: StringFilter | null;
  requestVerification?: RequestVerificationWhereInput | null;
  requestVerificationId?: StringFilter | null;
  storePhotoCategory?: StorePhotoCategory | null;
  updatedAt?: DateTimeFilter | null;
}

export interface UpdatedStoreHistoryFilter {
  every?: UpdatedStoreHistoryWhereInput | null;
  none?: UpdatedStoreHistoryWhereInput | null;
  some?: UpdatedStoreHistoryWhereInput | null;
}

export interface UpdatedStoreHistoryWhereInput {
  admin?: AdminWhereInput | null;
  adminId?: NullableStringFilter | null;
  AND?: UpdatedStoreHistoryWhereInput[] | null;
  createdAt?: DateTimeFilter | null;
  currentStoreData?: StringFilter | null;
  id?: StringFilter | null;
  NOT?: UpdatedStoreHistoryWhereInput[] | null;
  OR?: UpdatedStoreHistoryWhereInput[] | null;
  store?: StoreWhereInput | null;
  storeId?: StringFilter | null;
  updatedAt?: DateTimeFilter | null;
  updatedAttributes?: StringFilter | null;
}

export interface UpdatedUserHistoryFilter {
  every?: UpdatedUserHistoryWhereInput | null;
  none?: UpdatedUserHistoryWhereInput | null;
  some?: UpdatedUserHistoryWhereInput | null;
}

export interface UpdatedUserHistoryWhereInput {
  admin?: AdminWhereInput | null;
  adminId?: NullableStringFilter | null;
  AND?: UpdatedUserHistoryWhereInput[] | null;
  createdAt?: DateTimeFilter | null;
  currentUserData?: StringFilter | null;
  id?: StringFilter | null;
  NOT?: UpdatedUserHistoryWhereInput[] | null;
  OR?: UpdatedUserHistoryWhereInput[] | null;
  updatedAt?: DateTimeFilter | null;
  updatedAttributes?: StringFilter | null;
  user?: UserWhereInput | null;
  userId?: StringFilter | null;
}

export interface UserFilter {
  every?: UserWhereInput | null;
  none?: UserWhereInput | null;
  some?: UserWhereInput | null;
}

export interface UserWhereInput {
  addedBy?: NullableStringFilter | null;
  agreedTnCDate?: NullableDateTimeFilter | null;
  agreedTnCVersion?: NullableIntFilter | null;
  agreementStatementRegal?: AgreementRegalStatementWhereInput | null;
  AND?: UserWhereInput[] | null;
  betaTester?: NullableBooleanFilter | null;
  createdAt?: DateTimeFilter | null;
  createFrom?: FlagCreateUser | null;
  dokuRedeemOrders?: DokuRedeemOrderFilter | null;
  draftOrder?: DraftOrderFilter | null;
  id?: StringFilter | null;
  inactivatedBy?: NullableStringFilter | null;
  inactiveDate?: NullableDateTimeFilter | null;
  logChangePhoneNumber?: LogChangePhoneNumberFilter | null;
  managerUserId?: StringFilter | null;
  missionActivityLog?: MissionActivityLogFilter | null;
  NOT?: UserWhereInput[] | null;
  OR?: UserWhereInput[] | null;
  orderReference?: OrderReferenceFilter | null;
  ownerVerified?: NullableBooleanFilter | null;
  phoneNumber?: StringFilter | null;
  profile?: ProfileWhereInput | null;
  RequestAgent?: RequestAgentFilter | null;
  requestVerification?: RequestVerificationWhereInput | null;
  role?: Role | null;
  status?: StatusActive | null;
  store?: StoreWhereInput | null;
  storeId?: StringFilter | null;
  termsAndCondition?: TermsAndCondition | null;
  updatedAt?: DateTimeFilter | null;
  updatedUserHistory?: UpdatedUserHistoryFilter | null;
  updateNoticeTnCDate?: NullableDateTimeFilter | null;
  updateNoticeTnCVersion?: NullableIntFilter | null;
  verifiedDate?: NullableDateTimeFilter | null;
  whatsAppValidation?: NullableBooleanFilter | null;
  whatsAppValidationTimeStamp?: NullableDateTimeFilter | null;
}

export interface WinnersDataInput {
  createEdit: WinnersInput[];
  delete: DeleteWinners[];
}

export interface WinnersFilter {
  every?: WinnersWhereInput | null;
  none?: WinnersWhereInput | null;
  some?: WinnersWhereInput | null;
}

export interface WinnersInput {
  email: string;
  id?: string | null;
  name: string;
  notified: boolean;
  storeName: string;
}

export interface WinnersWhereInput {
  AND?: WinnersWhereInput[] | null;
  competition?: CompetitionWhereInput | null;
  competitionId?: NullableStringFilter | null;
  createdAt?: DateTimeFilter | null;
  email?: StringFilter | null;
  id?: StringFilter | null;
  name?: StringFilter | null;
  NOT?: WinnersWhereInput[] | null;
  notified?: BooleanFilter | null;
  OR?: WinnersWhereInput[] | null;
  storeName?: StringFilter | null;
  updatedAt?: DateTimeFilter | null;
}

export interface createBUContact {
  buContacts: BUContactInput[];
}

export interface deleteBUContact {
  buContacts: DeleteBUContact;
}

export interface updateBUContact {
  buContacts: BUContactInputWithId[];
}

//==============================================================
// END Enums and Input Objects
//==============================================================
